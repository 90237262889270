import { AlarmCreationForm } from './AlarmCreationForm';
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { Fieldset } from 'primereact/fieldset';
import { LeafProps, NodeProps, Treeview } from '@common/treeview';
import { Project } from '@api';
import { Toast } from 'primereact/toast';
import { findResourceById } from '@services/utils';
import { useAlarmDocumentDataUtils } from './useAlarmDocumentUtils';
import { useCallback, useMemo, useRef, useState } from 'react';

function documentDataToTreeView(documentData: Project.DocumentData, rename: string) {
  const recursivelyParseLevel = (levels?: Project.ResourceTreeElement[], parentId?: string) => {
    return levels?.map((level) => {
      const id = parentId ? `${parentId}.${level.data.id}` : level.data.id;
      const alarmItem = documentData.resourceTree
        ? findResourceById(documentData.resourceTree, level.data.id)
        : undefined;
      const levelItem: NodeProps | LeafProps = {
        id,
        title: level.name,
        icon: 'pi',
        open: false,
        parentFound: false,
        found: false,
        hidden: false,
        editable: false,
        autoedit: level.data.id === rename,
      };
      if (alarmItem.children && !alarmItem.children.some((child) => child.name === 'Events')) {
        levelItem.actions = [
          { icon: 'pi pi-plus', code: 'newAlarm' },
          { icon: 'pi pi-folder', code: 'newStructure' },
          { icon: 'pi pi-pencil', code: 'edit' },
          { icon: 'pi pi-times', code: 'deleteItem' },
        ];
        levelItem.children = recursivelyParseLevel(level.children, id);
      } else {
        levelItem.actions = [
          { icon: 'pi pi-pencil', code: 'edit' },
          { icon: 'pi pi-times', code: 'deleteItem' },
          { icon: 'pi pi-link', code: 'link' },
        ];
      }
      if (parentId) {
        levelItem.parentFound = true;
      }
      return levelItem;
    });
  };

  return [
    {
      title: '\\',
      id: 'root',
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      actions: [
        { icon: 'pi pi-plus', code: 'newAlarm' },
        { icon: 'pi pi-folder', code: 'newStructure' },
      ],
      children: recursivelyParseLevel(documentData.resourceTree) ?? [],
    },
  ];
}

export const AlarmDocumentEditor: DocumentEditorComponent = ({
  data: documentData,
  onDataChange: onDocumentDataChange,
  documentId,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [rename, setRename] = useState<string>();
  const [selectedField, setSelectedField] = useState<string | undefined>();

  const toast = useRef<Toast>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const alarmsTree = useMemo(() => documentDataToTreeView(documentData, rename), [documentData]);
  const selectedAlarmField = useMemo(() => (selectedField ? documentData : undefined), [documentData, selectedField]);

  const { createItem, renameItem, moveItem } = useAlarmDocumentDataUtils({
    documentData,
    onDocumentDataChange,
    documentId: documentId.documentId,
  });

  const notifyAction = (action: string, data: string) => {
    const dataObj = JSON.parse(data);
    const SUCCESS_TOAST_OPTIONS = {
      severity: 'success' as const,
      summary: 'Success',
      detail: '',
    };

    const ERROR_TOAST_OPTIONS = {
      severity: 'error' as const,
      summary: 'Error',
      detail: '',
    };

    const handleCreateItem = (type: 'alarm' | 'structure') => {
      try {
        const newId = createItem(dataObj.id, type);
        setRename(newId);
        toast.current.show({ ...SUCCESS_TOAST_OPTIONS, detail: 'Creato con successo' });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (ex: any) {
        toast.current.show({ ...ERROR_TOAST_OPTIONS, detail: ex.message });
      } finally {
        setShowSpinner(false);
      }
    };

    const handleDeleteItem = (itemId: string) => {
      try {
        setShowSpinner(true);
        const updatedResourceTree = documentData.resourceTree.filter((item) => item.data.id !== itemId);
        const updatedMetadata = { ...documentData.metadata };
        if (updatedMetadata[itemId]) {
          delete updatedMetadata[itemId];
        }
        onDocumentDataChange({
          ...documentData,
          resourceTree: updatedResourceTree,
          metadata: updatedMetadata,
        });

        toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Elemento rimosso con successo' });
      } catch (error) {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: error.message });
      } finally {
        setShowSpinner(false);
      }
    };

    switch (action) {
      case 'newAlarm':
        setShowSpinner(true);
        handleCreateItem('alarm');
        break;
      case 'newStructure':
        setShowSpinner(true);
        handleCreateItem('structure');
        break;
      case 'deleteItem':
        setSelectedField(undefined);
        handleDeleteItem(dataObj.id);
        break;
      case 'edit':
        renameItem(dataObj.id, dataObj.newTitle);
        break;
      case 'select':
        setSelectedField(dataObj.id);
        break;
      case 'drag-&-drop':
        if (dataObj.hasOwnProperty('startId') && dataObj.hasOwnProperty('endId')) {
          moveItem(dataObj.startId, dataObj.endId);
        }
        break;
    }
  };

  const handleAlarmChange = useCallback(
    (item: Project.AlarmMetaDataItem) => {
      if (selectedField) {
        const currentMetadata: Project.AlarmMetaDataItem = documentData.metadata?.[selectedField?.split('.').pop()] || {
          message: '',
          priority: Project.AlarmPriority.Error,
          alarmType: Project.AlarmType.ISA,
        };
        const updatedMetadata: Project.AlarmMetaData = {
          ...documentData.metadata,
          [selectedField?.split('.').pop()]: {
            ...currentMetadata,
            ...item,
          },
        };

        onDocumentDataChange({
          ...documentData,
          metadata: updatedMetadata,
        });
      } else {
        console.error('selectedField is undefined or invalid');
      }
    },
    [documentData, onDocumentDataChange, selectedField]
  );

  const isAlarmStructure = (): boolean => {
    const alarmItem = findResourceById(documentData.resourceTree, selectedField?.split('.').pop());
    if (alarmItem) {
      return !alarmItem.children.some((child) => child.name === 'Events');
    } else return false;
  };

  return (
    <>
      <div style={{ display: 'grid', height: '100%', gridTemplateColumns: '1fr 2fr', padding: '1rem', gap: '1em' }}>
        <Fieldset legend="Alarms" style={{ minHeight: '340px', height: '675px' }}>
          <div className="card flex flex-column">
            <Treeview
              json={JSON.stringify(alarmsTree)}
              selectedItems={[selectedField]}
              notifyAction={notifyAction}
              utility={{ spinner: showSpinner }}
            />
          </div>
        </Fieldset>
        <Toast ref={toast} />
        {selectedAlarmField && !isAlarmStructure() && (
          <Fieldset legend="Configuration" style={{ minHeight: '340px', maxHeight: '675px' }}>
            <AlarmCreationForm
              alarmItem={findResourceById(documentData.resourceTree, selectedField?.split('.').pop())}
              documentData={documentData}
              onChange={handleAlarmChange}
              documentId={documentId}
              onDocumentDataChange={onDocumentDataChange}
            />
          </Fieldset>
        )}
      </div>
    </>
  );
};
