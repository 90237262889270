/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { Button } from 'primereact/button';
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { Fieldset } from 'primereact/fieldset';
import { LeafProps, NodeProps, Treeview, TreeviewProps } from '@components/common/treeview';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api';
import { TreeLink } from '@components/tree-link/TreeLink';
import { deepCopy } from '@services/utils';
import { loader } from '@monaco-editor/react';
import AutoFocusDiv from '@components/common/AutoFocusDiv';
import React, { useEffect, useRef, useState } from 'react';

export const ScriptDocumentEditor: DocumentEditorComponent = ({
  data,
  status,
  onDataChange,
  onStatusChange,
  documentId,
}) => {
  // const localDocumentData = useRef<Project.DocumentData | null>(null);
  const editorRef = useRef(null);
  const statusRef = useRef<Record<string, any>>({});
  const editorLoaded = useRef(false);
  const linkOverlay = useRef<OverlayPanel>(null);
  const [startResourcePoint, setStartResourcePoint] = useState<
    (Project.ResourceLinkPoint & { pinType: Project.PinType }) | undefined
  >();
  const startResourcePinType = useRef<Project.PinType | null>(null);

  //const linkOverlayEvent = useRef<OverlayPanel>(null);
  // const [startResourcePointEvent, setStartResourcePointEvent] = useState<
  //   (Project.ResourceLinkPoint & { pinType: Project.PinType }) | undefined
  // >();

  // Conversione e stampa della struttura dell'albero
  const treeviewObj: NodeProps[] = [];
  const [treeviewJson, setTreeview] = useState(JSON.stringify(treeviewObj));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedItems, setSelectedItems] = useState(Array<string>());

  useEffect(() => {
    const treeviewObj: NodeProps[] = [
      {
        title: 'PINS',
        id: `PINS${documentId.projectId}.${documentId.documentId}`,
        open: true,
        parentFound: false,
        found: false,
        hidden: false,
        editable: false,
        icon: undefined,
        children: [
          {
            title: 'IN',
            id: `INPIN${documentId.projectId}.${documentId.documentId}`,
            open: true,
            parentFound: false,
            found: false,
            hidden: false,
            editable: false,
            icon: undefined,
            actions: [{ icon: 'pi pi-plus', code: 'newIn' }],
            children: getPinLeaves(data.resourceTree, Project.PinType.In) ?? [],
          },
          {
            title: 'OUT',
            id: `OUTPIN${documentId.projectId}.${documentId.documentId}`,
            open: true,
            parentFound: false,
            found: false,
            hidden: false,
            editable: false,
            icon: undefined,
            actions: [{ icon: 'pi pi-plus', code: 'newOut' }],
            children: getPinLeaves(data.resourceTree, Project.PinType.Out) ?? [],
          },
        ],
      },
    ];

    setTreeview(JSON.stringify(treeviewObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.resourceTree]);

  const getPinLeaves = (pins?: Project.ResourceTreeElement[], pinType?: Project.PinType) => {
    return pins
      ?.filter(function (pin) {
        if (pin.pinType !== pinType) return false;
        return true;
      })
      .map((pin) => {
        /*
        if(level.pinType != pinType)
           return null; */

        const linkTypeToken = pin.pinType === Project.PinType.In ? '-in' : '-out';
        const levelItem: LeafProps = {
          id: pin.data.id ?? null,
          title: pin.name,
          icon: 'pi',
          childFound: false,
          selected: false,
          found: false,
          hidden: false,
          editable: true,
          actions: [
            /*{ icon: 'pi pi-times', code: 'deleteTag' },*/
            { icon: 'pi pi-link', code: 'link' + linkTypeToken },
          ],
        };

        return levelItem;
      });
  };

  async function notifyAction(action: string, data: string, event: any) {
    const dt = data ? JSON.parse(data) : {};

    switch (action) {
      case 'newIn':
        if (dt.hasOwnProperty('id')) {
          createScriptPin(dt.id, Project.PinType.In);
          //const id = `scriptPinIN${(documentData_.metadata as Project.TagMetadata).items.length}-${documentId}`;
        }
        break;
      case 'newOut':
        createScriptPin(dt.id, Project.PinType.Out);
        break;
      case 'edit':
        renameScriptPin(dt.id, dt.newTitle);
        break;
      case 'link-in':
      case 'link-out':
        startResourcePinType.current = action === 'link-in' ? Project.PinType.In : Project.PinType.Out;
        setStartResourcePoint({
          documentId,
          path: dt.id.split('.').map((part: string) => ({ idx: part })),
          pinType: action === 'link-in' ? Project.PinType.In : Project.PinType.Out,
        });
        linkOverlay.current.toggle(event);
        break;
    }
  }

  function createScriptPin(parentId: string, pinType_: Project.PinType) {
    const documentData: Project.DocumentData = deepCopy(data);
    const lenght = documentData?.resourceTree?.length ?? 0;
    const id = `scriptPin${lenght}-${parentId}`;
    const newPinIn: Project.ResourceTreeElement = {
      name: pinType_ === Project.PinType.In ? 'newPin' : 'newPout',
      data: { id },
      pinType: pinType_,
    };

    if (documentData.resourceTree === undefined) documentData.resourceTree = [];

    documentData.resourceTree.push(newPinIn);
    onDataChange(documentData);
  }

  function renameScriptPin(pinIdToRename: string, newName: string) {
    const documentData = deepCopy(data);

    documentData.resourceTree = documentData.resourceTree.map((item: Project.ResourceTreeElement) => {
      if (item.data.id === pinIdToRename) {
        return {
          ...item,
          name: newName,
        };
      } else {
        return item;
      }
    });

    onDataChange(documentData);
  }

  loader.init().then((monaco) => {
    if (editorLoaded.current) return;
    else editorLoaded.current = true;

    //verifichiamo se il linguaggio custom non è ancora registrato
    const registeredLanguages = monaco.languages.getLanguages();
    if (!registeredLanguages.some((e) => e.id === 'mylang')) {
      const keywords = ['struct', 'fun', 'let', 'for', 'while'];
      const typeKeywords = ['numeric', 'string'];
      monaco.languages.register({ id: 'mylang' });
      monaco.languages.setMonarchTokensProvider('mylang', {
        keywords: keywords,
        typeKeywords: typeKeywords,
        operators: [
          '=',
          '>',
          '<',
          '!',
          '~',
          '?',
          ':',
          '==',
          '<=',
          '>=',
          '!=',
          '&&',
          '||',
          '++',
          '--',
          '+',
          '-',
          '*',
          '/',
          '&',
          '|',
          '^',
          '%',
          '<<',
          '>>',
          '>>>',
          '+=',
          '-=',
          '*=',
          '/=',
          '&=',
          '|=',
          '^=',
          '%=',
          '<<=',
          '>>=',
          '>>>=',
        ],
        symbols: /[=><!~?:&|+\-*\/\^%]+/,
        escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
        tokenizer: {
          root: [
            [
              /[a-z_$][\w$]*/,
              {
                cases: {
                  '@typeKeywords': 'typeKeyword',
                  '@keywords': 'keyword',
                  '@default': 'identifier',
                },
              },
            ],
            [/[A-Z][\w\$]*/, 'type.identifier'],
            {
              include: '@whitespace',
            },
            [/[{}()\[\]]/, '@brackets'],
            [/[<>](?!@symbols)/, '@brackets'],
            [
              /[a-z_$][\w$]*/,
              {
                cases: {
                  '@operators': 'operator',
                  '@default': '',
                },
              },
            ],
            [
              /@\s*[a-zA-Z_\$][\w\$]*/,
              {
                token: 'annotation',
                log: 'annotation token: $0',
              },
            ],
            [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
            [/0[xX][0-9a-fA-F]+/, 'number.hex'],
            [/\d+/, 'number'],
            [/[;,.]/, 'delimiter'],
            [/"([^"\\]|\\.)*$/, 'string.invalid'],
            [
              /"/,
              {
                token: 'string.quote',
                bracket: '@open',
                next: '@string',
              },
            ],
            [/'[^\\']'/, 'string'],
            [/(')(@escapes)(')/, ['string', 'string.escape', 'string']],
            [/'/, 'string.invalid'],
          ],
          comment: [
            [/[^\/*]+/, 'comment'],
            [/\/\*/, 'comment', '@push'],
            ['\\*/', 'comment', '@pop'],
            [/[\/*]/, 'comment'],
          ],
          string: [
            [/[^\\"]+/, 'string'],
            [/@escapes/, 'string.escape'],
            [/\\./, 'string.escape.invalid'],
            [
              /"/,
              {
                token: 'string.quote',
                bracket: '@close',
                next: '@pop',
              },
            ],
          ],
          whitespace: [
            [/[ \t\r\n]+/, 'white'],
            [/\/\*/, 'comment', '@comment'],
            [/\/\/.*$/, 'comment'],
          ],
        },
      });

      const suggWords = [...typeKeywords, ...keywords];
      monaco.languages.registerCompletionItemProvider('mylang', {
        provideCompletionItems: function (model, position) {
          const suggestions = [
            ...suggWords.map((k) => {
              return {
                label: k,
                kind: monaco.languages.CompletionItemKind.Keyword,
                insertText: k,
                range: {
                  startLineNumber: position.lineNumber,
                  endLineNumber: position.lineNumber,
                  startColumn: model.getWordUntilPosition(position).startColumn,
                  endColumn: model.getWordUntilPosition(position).endColumn,
                },
              };
            }),
          ];
          // Compute the suggestions
          return {
            suggestions: suggestions.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, []), //rimuove possibili duplicati
          };
        },
      });
    }

    monaco.editor.defineTheme('mylang-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { token: 'keyword', foreground: '#0000FF' },
        { token: 'typeKeyword', foreground: '#00FF00' },
      ],
      colors: {},
    });

    //monaco.languages.SelectedSuggestionInfo.

    monaco.editor.setTheme('mylang-theme');

    const wrapper = document.getElementById('monaco-editor-div');
    //impostiamo l'altezza corretta per l'editor
    const height = wrapper.parentElement.parentElement.parentElement.parentElement.clientHeight - 60;
    //let width =  (wrapper.clientWidth -60);
    wrapper.style.height = height + 'px';
    //wrapper.style.width = (width+'px');

    const editor = monaco.editor.create(wrapper, {
      value: data?.metadata?.text ?? '//Testo dello script',
      language: 'mylang',
      theme: 'mylang-theme',
      scrollBeyondLastLine: false,
    });
    editorRef.current = editor;

    let lineNum = 1;
    let col = 1;
    if (status.hasOwnProperty(documentId.documentId)) {
      lineNum = status[documentId.documentId]?.lineNumber;
      col = status[documentId.documentId]?.column;
    }

    editor.setPosition({
      lineNumber: lineNum,
      column: col,
    });
    editor.focus();

    editor.onDidChangeModelContent((e) => {
      saveStatus();
      const documentData = deepCopy(data);
      documentData.metadata = { text: editor.getValue() };
      // localDocumentData.current = {metadata:{text:editor.getValue()}}
      // onDataChange(localDocumentData.current)
      onDataChange(documentData);
    });

    monaco.editor.onWillDisposeModel((model) => {
      model.dispose();
    });
  });

  const saveStatus = () => {
    const position: any = editorRef.current.getPosition();
    const clone = deepCopy(statusRef.current);
    clone[documentId.documentId] = { lineNumber: position?.lineNumber, column: position?.column };
    statusRef.current = clone;
    onStatusChange(statusRef.current);
  };

  const pr: TreeviewProps = {
    json: treeviewJson,
    selectedItems: selectedItems,
    notifyAction: notifyAction,
  };

  const handleLinkChange = (resourceLinks: Project.ResourceLink[]) => {
    onDataChange({
      ...data,
      resourceLinks,
    });
  };

  const handleEventLinkRun = (e: React.MouseEvent) => {
    setStartResourcePoint({
      documentId,
      path: [{ idx: 'events' }, { idx: 'OnRun' }],
      pinType: Project.PinType.InOut,
    });
    //linkOverlayEvent.current.toggle(e);
    linkOverlay.current.toggle(e);
  };

  return (
    <div className="grid" style={{ width: '100%' }}>
      <div className="col-3 script-pins-treeview">
        <div className="flex flex-column">
          <Fieldset legend="Signals">
            <Treeview {...pr} />
            <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
              <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
                {startResourcePoint && (
                  <TreeLink
                    startResourcePoint={startResourcePoint}
                    startResourcePinType={startResourcePoint.pinType}
                    resourceLinks={data.resourceLinks}
                    onChangeData={handleLinkChange}
                  />
                )}
              </AutoFocusDiv>
            </OverlayPanel>
          </Fieldset>

          <Fieldset legend="Events" className="mt-5">
            <div className="flex flex-row items-center gap-4">
              <div className="mt-3">OnRun</div>
              <Button
                type="button"
                icon="pi pi-link"
                onClick={(e) => handleEventLinkRun(e)}
                text
                aria-label="Resolution"
              />
            </div>
          </Fieldset>
        </div>
      </div>

      <div id="monaco-editor-div" data-testid="monaco-editor-div" className="col-9" onClick={saveStatus}></div>
    </div>
  );
};
