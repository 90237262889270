/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventsManager } from './view-manager/widget-handler/events-manager';
import { LeafProps, NodeProps, TreeviewProps, TreeviewSVG } from '@components/common/treeview';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useLayersEditorStore } from '@stores/layersStore';

let childrenCounter;

/**
 * Creazione nodo del widget nel tree
 * @param treeElement struttura tree
 * @param singleWidget widget da inserire nella struttura
 */
export function createTreeNodeWidget(treeElement: (NodeProps | LeafProps)[], singleWidget: any, layersEditorWidgets) {
  childrenCounter = 1;
  const svgElement = document.getElementById(singleWidget.data.id).querySelector('svg');
  if (svgElement) {
    svgElement.id = singleWidget.data.id;

    const visibilityIcon = svgElement.style.display === 'none' ? 'pi pi-eye-slash' : 'pi pi-eye';
    const lockIcon = svgElement.dataset.locked === 'true' ? 'pi pi-lock' : 'pi pi-lock-open';

    const newElement: NodeProps | LeafProps = {
      title: singleWidget.name,
      id: singleWidget.data.id,
      open: false,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      icon: undefined,
      children: createChildElements(svgElement, singleWidget.data.id),
      actions: [
        { icon: visibilityIcon, code: 'show' },
        { icon: lockIcon, code: 'lock' },
      ],
    };

    document.getElementById(singleWidget.data.id).querySelector('svg').innerHTML = svgElement.innerHTML;
    treeElement.push(newElement);
  }

  /**
   * Creazione ricorsiva dei leaf/node dei children all'interno del tree
   * @param element elemento di cui si stanno creando i children
   * @param rootId id del widget di partenza
   * @returns
   */
  function createChildElements(element: Element, rootId: string): Array<NodeProps | LeafProps> {
    const childElements: Array<NodeProps | LeafProps> = [];

    element.childNodes.forEach((childNode) => {
      if (childNode instanceof Element) {
        const tagName = childNode.tagName.toLowerCase();

        if (skipElement(tagName)) {
          return;
        }

        const uniqueId = childNode.id || `${rootId}-${childrenCounter++}`;
        childNode.id = uniqueId;

        const visibilityIcon = (childNode as HTMLElement).style.display === 'none' ? 'pi pi-eye-slash' : 'pi pi-eye';
        const lockIcon = (childNode as HTMLElement).dataset.locked === 'true' ? 'pi pi-lock' : 'pi pi-lock-open';

        const isSubwidget = layersEditorWidgets.some((element) => element.data.id === uniqueId);

        if (isLeafElement(tagName)) {
          const leafElement: LeafProps = {
            id: uniqueId,
            title: childNode.id ? tagName + '-' + childNode.id : tagName,
            found: false,
            hidden: false,
            childFound: false,
            editable: false,
            selected: false,
            icon: undefined,
            actions: [
              ...(isSubwidget === false ? [{ icon: 'pi pi-flag-fill', code: 'subwidget' }] : []),
              { icon: 'pi pi-pencil', code: 'edit' },
              {
                icon: visibilityIcon,
                code: 'show',
                disabled: (childNode as HTMLElement).dataset.toggleFromAbove === 'true' ? true : false,
              },
              {
                icon: lockIcon,
                code: 'lock',
                disabled: (childNode as HTMLElement).dataset.lockFromAbove === 'true' ? true : false,
              },
              {
                icon: 'pi pi-trash',
                code: 'delete',
                disabled: (childNode as HTMLElement).dataset.locked === 'true' ? true : false,
              },
            ],
          };
          childElements.push(leafElement);
        } else {
          const nodeElement: NodeProps = {
            id: uniqueId,
            title: childNode.id ? tagName + '-' + childNode.id : tagName,
            open: false,
            parentFound: false,
            found: false,
            hidden: false,
            editable: false,
            icon: undefined,
            children: createChildElements(childNode, rootId), //ricorsione sui children
            actions: [
              ...(isSubwidget === false ? [{ icon: 'pi pi-flag-fill', code: 'subwidget' }] : []),
              { icon: 'pi pi-pencil', code: 'edit' },
              {
                icon: visibilityIcon,
                code: 'show',
                disabled: (childNode as HTMLElement).dataset.toggleFromAbove === 'true' ? true : false,
              },
              {
                icon: lockIcon,
                code: 'lock',
                disabled: (childNode as HTMLElement).dataset.lockFromAbove === 'true' ? true : false,
              },
              {
                icon: 'pi pi-trash',
                code: 'delete',
                disabled: (childNode as HTMLElement).dataset.locked === 'true' ? true : false,
              },
            ],
          };
          childElements.push(nodeElement);
        }
      }
    });

    return childElements;
  }

  /**
   * Controllo che l'elemento SVG vada inserito in una Leaf
   * @param tagName tag dell'elemento dell'svg preso in analisi
   * @returns true se deve essere una Leaf altrimenti false
   */
  function isLeafElement(tagName: string): boolean {
    const leafElements = [
      'circle',
      'ellipse',
      'line',
      'polygon',
      'polyline',
      'rect',
      'path',
      'image',
      'text',
      'textPath',
    ];
    return leafElements.includes(tagName);
  }

  /**
   * Controllo che l'elemento SVG sia da scartare o meno
   * @param tagName tag dell'elemento dell'svg preso in analisi
   * @returns true se va scartato altrimenti false
   */
  function skipElement(tagName: string): boolean {
    const skipElements = [
      'animate',
      'animateMotion',
      'animateTransform',
      'desc',
      'title',
      'script',
      'style',
      'set',
      'defs',
    ];
    return skipElements.includes(tagName);
  }
}

export const LayersEditor = () => {
  const treeviewObj: any[] = [];
  const { layersEditorWidgets } = useLayersEditorStore();
  const [tree, setTree] = useState(layersEditorWidgets);
  const [treeviewJSON, setTreeviewJSON] = useState(JSON.stringify(treeviewObj));
  const [selectedItems, setSelectedItems] = useState(Array<string>());
  const [showSpinner, setshowSpinner] = useState(false);
  const toast = useRef<Toast>();
  // let childrenCounter;

  // /**
  //  * Creazione nodo del widget nel tree
  //  * @param treeElement struttura tree
  //  * @param singleWidget widget da inserire nella struttura
  //  */
  // function createTreeNodeWidget(treeElement: (NodeProps | LeafProps)[], singleWidget: any) {
  //   childrenCounter = 1;
  //   const svgElement = document.getElementById(singleWidget.data.id).querySelector('svg');
  //   if (svgElement) {
  //     svgElement.id = singleWidget.data.id;

  //     const visibilityIcon = svgElement.style.display === 'none' ? 'pi pi-eye-slash' : 'pi pi-eye';
  //     const lockIcon = svgElement.dataset.locked === 'true' ? 'pi pi-lock' : 'pi pi-lock-open';

  //     const newElement: NodeProps | LeafProps = {
  //       title: singleWidget.name,
  //       id: singleWidget.data.id,
  //       open: false,
  //       parentFound: false,
  //       found: false,
  //       hidden: false,
  //       editable: false,
  //       icon: undefined,
  //       children: createChildElements(svgElement, singleWidget.data.id),
  //       actions: [
  //         { icon: 'pi pi-pencil', code: 'edit' },
  //         { icon: visibilityIcon, code: 'show' },
  //         { icon: lockIcon, code: 'lock' },
  //       ],
  //     };

  //     document.getElementById(singleWidget.data.id).querySelector('svg').innerHTML = svgElement.innerHTML;
  //     treeElement.push(newElement);
  //   }
  // }

  // /**
  //  * Creazione ricorsiva dei leaf/node dei children all'interno del tree
  //  * @param element elemento di cui si stanno creando i children
  //  * @param rootId id del widget di partenza
  //  * @returns
  //  */
  // function createChildElements(element: Element, rootId: string): Array<NodeProps | LeafProps> {
  //   const childElements: Array<NodeProps | LeafProps> = [];

  //   element.childNodes.forEach((childNode) => {
  //     if (childNode instanceof Element) {
  //       const tagName = childNode.tagName.toLowerCase();

  //       if (skipElement(tagName)) {
  //         return;
  //       }

  //       const uniqueId = childNode.id || `${rootId}-${childrenCounter++}`;
  //       childNode.id = uniqueId;

  //       const visibilityIcon = (childNode as HTMLElement).style.display === 'none' ? 'pi pi-eye-slash' : 'pi pi-eye';
  //       const lockIcon = (childNode as HTMLElement).dataset.locked === 'true' ? 'pi pi-lock' : 'pi pi-lock-open';

  //       const isSubwidget = layersEditorWidgets.some((element) => element.data.id === uniqueId);

  //       if (isLeafElement(tagName)) {
  //         const leafElement: LeafProps = {
  //           id: uniqueId,
  //           title: childNode.id ? tagName + '-' + childNode.id : tagName,
  //           found: false,
  //           hidden: false,
  //           childFound: false,
  //           editable: false,
  //           selected: false,
  //           icon: undefined,
  //           actions: [
  //             ...(isSubwidget === false ? [{ icon: 'pi pi-flag-fill', code: 'subwidget' }] : []),
  //             { icon: 'pi pi-pencil', code: 'edit' },
  //             {
  //               icon: visibilityIcon,
  //               code: 'show',
  //               disabled: (childNode as HTMLElement).dataset.toggleFromAbove === 'true' ? true : false,
  //             },
  //             { icon: lockIcon, code: 'lock' },
  //             {
  //               icon: 'pi pi-trash',
  //               code: 'delete',
  //               disabled: (childNode as HTMLElement).dataset.locked === 'true' ? true : false,
  //             },
  //           ],
  //         };
  //         childElements.push(leafElement);
  //       } else {
  //         const nodeElement: NodeProps = {
  //           id: uniqueId,
  //           title: childNode.id ? tagName + '-' + childNode.id : tagName,
  //           open: false,
  //           parentFound: false,
  //           found: false,
  //           hidden: false,
  //           editable: false,
  //           icon: undefined,
  //           children: createChildElements(childNode, rootId), //ricorsione sui children
  //           actions: [
  //             ...(isSubwidget === false ? [{ icon: 'pi pi-flag-fill', code: 'subwidget' }] : []),
  //             { icon: 'pi pi-pencil', code: 'edit' },
  //             {
  //               icon: visibilityIcon,
  //               code: 'show',
  //               disabled: (childNode as HTMLElement).dataset.toggleFromAbove === 'true' ? true : false,
  //             },
  //             { icon: lockIcon, code: 'lock' },
  //             {
  //               icon: 'pi pi-trash',
  //               code: 'delete',
  //               disabled: (childNode as HTMLElement).dataset.locked === 'true' ? true : false,
  //             },
  //           ],
  //         };
  //         childElements.push(nodeElement);
  //       }
  //     }
  //   });

  //   return childElements;
  // }

  // /**
  //  * Controllo che l'elemento SVG vada inserito in una Leaf
  //  * @param tagName tag dell'elemento dell'svg preso in analisi
  //  * @returns true se deve essere una Leaf altrimenti false
  //  */
  // function isLeafElement(tagName: string): boolean {
  //   const leafElements = [
  //     'circle',
  //     'ellipse',
  //     'line',
  //     'polygon',
  //     'polyline',
  //     'rect',
  //     'path',
  //     'image',
  //     'text',
  //     'textPath',
  //   ];
  //   return leafElements.includes(tagName);
  // }

  // /**
  //  * Controllo che l'elemento SVG sia da scartare o meno
  //  * @param tagName tag dell'elemento dell'svg preso in analisi
  //  * @returns true se va scartato altrimenti false
  //  */
  // function skipElement(tagName: string): boolean {
  //   const skipElements = [
  //     'animate',
  //     'animateMotion',
  //     'animateTransform',
  //     'desc',
  //     'title',
  //     'script',
  //     'style',
  //     'set',
  //     'defs',
  //   ];
  //   return skipElements.includes(tagName);
  // }

  /**
   * Ricerca container SVG in base all'id del child
   * @param id id dell'elemento di cui va ricercato il container
   * @returns
   */
  function findContainer(id) {
    const element = document.getElementById(id);

    if (!element) {
      console.log(`Elemento ID: "${id}" non trovato`);
      return null;
    }

    //ricerca nodo radice
    let parent = element;
    while (parent && parent.tagName.toLowerCase() !== 'svg') {
      parent = parent.parentNode as HTMLElement;
    }

    //ulteriore controllo
    if (parent && parent.tagName.toLowerCase() === 'svg') {
      return parent;
    } else {
      console.log(`Elemento <svg> non trovato`);
      return null;
    }
  }

  /**
   * Eliminazione ricorsiva grafica dell'elemento dal parent SVG
   * @param svgElement parent SVG
   * @param elementId id dell'elemento da eliminare
   * @returns true se l'elemento è stato eliminato correttamente, altrimenti false
   */
  function deleteSvgElementRecursive(svgElement: HTMLElement, elementId: string) {
    //funzione ricorsiva
    function removeElementRecursive(parent: HTMLElement, childId: string): boolean {
      const children = Array.from(parent.children);
      for (const child of children) {
        if (child.id === childId) {
          //cancellazione
          parent.removeChild(child);
          return true;
        } else if (child.children.length > 0) {
          //ricorsione ricerca
          const removed = removeElementRecursive(child as HTMLElement, childId);
          if (removed) return true;
        }
      }
      return false;
    }

    const elementToRemove = svgElement.querySelector(`#${elementId}`);
    if (elementToRemove) {
      return removeElementRecursive(svgElement, elementId);
    } else {
      return false;
    }
  }

  /**
   * Eliminazione elemento dal tree
   * @param tree tree da modificare
   * @param elementId id dell'elemento da eliminare
   * @returns
   */
  function removeTreeElementById(tree: (NodeProps | LeafProps)[], elementId: string): (NodeProps | LeafProps)[] {
    return tree.filter((node) => {
      //ricorsione sui children
      if ('children' in node && node.children) {
        node.children = removeTreeElementById(node.children, elementId);
      }
      return node.id !== elementId;
    });
  }

  /**
   * Eliminazione elemento dal proprio parent SVG
   * @param id id elemento da eliminare
   */
  function deleteSvgElement(id: string): void {
    const svgElement = findContainer(id);
    if (svgElement) {
      //rimozione da svg grafico
      if (deleteSvgElementRecursive(svgElement, id)) {
        const newSvgContent = new XMLSerializer().serializeToString(svgElement);
        EventsManager.sendEvent(svgElement.id, 'SVGNewURL', { svgUrl: newSvgContent }, true);
      }
      //rimozione da tree dei layers
      setTree((prevTree) => removeTreeElementById(prevTree, id));

      //aggiornamento json
      const updatedTreeViewObj = [];
      Object.entries(tree).forEach(([key, element]) => {
        createTreeNodeWidget(updatedTreeViewObj, element, layersEditorWidgets);
      });
      setTreeviewJSON(JSON.stringify(updatedTreeViewObj));
    } else {
      console.log('Elemento non trovato');
    }
  }

  /**
   * Toggle visibilità dell'elemento
   * @param elementId id dell'elemento di cui si è cliccata l'icona
   * @returns
   */
  function toggleVisibility(elementId) {
    const svgElement = document.getElementById(elementId);
    if (!svgElement) return;

    //visibilità grafica
    function toggleElementVisibility(element: HTMLElement, toggleFromAbove?: boolean) {
      const isHidden = element.style.display === 'none';
      element.dataset.toggleFromAbove = toggleFromAbove ? 'true' : 'false';
      if (isHidden && element.dataset.toggleFromAbove === 'true' && toggleFromAbove) {
        return;
      }
      element.style.display = isHidden ? '' : 'none';
    }

    //ricorsione children
    function toggleVisibilityRecursive(element, toggleFromAbove?) {
      toggleElementVisibility(element, toggleFromAbove);
      Array.from(element.children).forEach((child) => {
        toggleVisibilityRecursive(child, element.style.display === 'none' || toggleFromAbove);
      });
    }

    //richiamo della funzione
    toggleVisibilityRecursive(svgElement);
    //aggiornamento tree
    const updatedTreeViewObj = [];
    Object.entries(tree).forEach(([key, element]) => {
      createTreeNodeWidget(updatedTreeViewObj, element, layersEditorWidgets);
    });
    setTreeviewJSON(JSON.stringify(updatedTreeViewObj));
  }

  /**
   * Toggle blocco dell'elemento (non può essere eliminato)
   * @param elementId id dell'elemento di cui si è cliccata l'icona
   * @returns
   */
  function toggleLock(elementId: string) {
    const svgElement = document.getElementById(elementId);
    if (!svgElement) return;

    //lock all'elemento dom
    function toggleElementLock(element: HTMLElement, lockFromAbove?: boolean) {
      const isLocked = element.dataset.locked === 'true';
      element.dataset.lockFromAbove = lockFromAbove ? 'true' : 'false';
      if (isLocked && element.dataset.lockFromAbove === 'true' && lockFromAbove) {
        return;
      }
      element.dataset.locked = isLocked ? 'false' : 'true';
    }

    //ricorsione children
    function toggleLockRecursive(element: HTMLElement, lockFromAbove?: boolean) {
      toggleElementLock(element, lockFromAbove);
      Array.from(element.children).forEach((child) => {
        toggleLockRecursive(child as HTMLElement, element.dataset.locked === 'true' || lockFromAbove);
      });
    }

    //richiamo della funzione
    toggleLockRecursive(svgElement);
    //aggiornamento tree
    const updatedTreeViewObj = [];
    Object.entries(tree).forEach(([key, element]) => {
      createTreeNodeWidget(updatedTreeViewObj, element, layersEditorWidgets);
    });
    setTreeviewJSON(JSON.stringify(updatedTreeViewObj));
  }

  /**
   * Aggiornamento struttura
   */
  useEffect(() => {
    setTree(layersEditorWidgets);
  }, [layersEditorWidgets]);

  /**
   * Aggiornamento JSON della struttura
   */
  useEffect(() => {
    if (tree) {
      const treeviewObj: any = [];

      Object.entries(tree).forEach(([key, element]) => {
        createTreeNodeWidget(treeviewObj, element, layersEditorWidgets);
      });

      setTreeviewJSON(JSON.stringify(treeviewObj));
    }
  }, [tree]);

  async function notifyAction(action: string, data: string) {
    const dt = data ? JSON.parse(data) : {};
    switch (action) {
      case 'select':
        if (dt.hasOwnProperty('id')) {
          setSelectedItems([dt.id]);
          const selectEvent = new CustomEvent('layers_click', {
            detail: { id: dt.id },
          });
          document.body.dispatchEvent(selectEvent);
        }
        break;
      case 'subwidget':
        const parentWidget = findContainer(dt.id).parentElement;
        EventsManager.sendEvent(parentWidget.id, 'SVGAddSubwidget', {
          subwidget: { id: dt.id, type: 'svg_subwidget', children: [] },
        });

        //aggiornamento layers
        if (!useLayersEditorStore.getState().layersEditorWidgets.some((widget) => widget.data.id === dt.id)) {
          const updatedWidgets = [
            ...useLayersEditorStore.getState().layersEditorWidgets,
            { name: 'svg_subwidget-' + dt.id, data: { id: dt.id, type: 'svg_subwidget' } },
          ];
          useLayersEditorStore.getState().setLayersEditorWidgets(updatedWidgets);
        }

        notifyAction('select', `{"action":"select","id":"${dt.id}"}`);
        break;
      case 'edit':
        if (dt.hasOwnProperty('id') && dt.hasOwnProperty('newTitle')) {
          const id = dt.id;
          const newId = dt.newTitle.split('-').slice(1).join('-');
          let duplicateId = false;

          //check id già esistente
          const widgets = tree.filter((element) => element.data.type !== 'svg_subwidget'); //widget svg caricati
          widgets.forEach((widget) => {
            const domElement = document.getElementById(widget.data.id);
            checkId(domElement, newId);
          });

          if (!duplicateId) {
            //non duplicato
            const svgElement = findContainer(id);
            if (svgElement) {
              const modifiedElement = svgElement.querySelector(`#${id}`);
              modifiedElement.id = newId;

              //rimozione bordi
              const border = svgElement.querySelector('.layer-selected');
              border.classList.remove('layer-selected');

              //svg aggiornato
              const newSvgContent = new XMLSerializer().serializeToString(svgElement);
              EventsManager.sendEvent(svgElement.id, 'SVGNewURL', { svgUrl: newSvgContent }, true);

              //tree aggiornato
              const updatedTreeViewObj = [];
              Object.entries(tree).forEach(([key, element]) => {
                createTreeNodeWidget(updatedTreeViewObj, element, layersEditorWidgets);
              });
              setTreeviewJSON(JSON.stringify(updatedTreeViewObj));
            }
          } else {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: "L'ID inserito è già esistente",
            });
          }

          //funzione ricorsiva per il controllo dell'id duplicato
          function checkId(element: HTMLElement, newId: string): boolean {
            if (element.id === newId) {
              duplicateId = true;
              return true;
            }
            for (const child of Array.from(element.children)) {
              if (checkId(child as HTMLElement, newId)) {
                duplicateId = true;
                return true;
              }
            }
          }
        }
        break;
      case 'show':
        if (dt.hasOwnProperty('id')) {
          toggleVisibility(dt.id);
        }
        break;
      case 'lock':
        if (dt.hasOwnProperty('id')) {
          toggleLock(dt.id);
        }
        break;
      case 'delete':
        if (dt.hasOwnProperty('id')) {
          deleteSvgElement(dt.id);
        }
        break;
      case 'drag-&-drop':
        console.log('drag-&-drop');
        break;
    }
  }

  const pr: TreeviewProps = {
    json: treeviewJSON,
    selectedItems: selectedItems,
    notifyAction: notifyAction,
    utility: { spinner: showSpinner },
  };

  document.addEventListener('svg_click_layer', (event: CustomEvent) => {
    const { id } = event.detail;
    //reset bordi
    const selectedElements = document.querySelectorAll('.layer-selected');
    selectedElements.forEach((element) => {
      element.classList.remove('layer-selected');
    });
    setSelectedItems([id]);
  });

  return (
    <>
      <Toast ref={toast} />
      {tree && Object.keys(tree).length > 0 ? (
        <TreeviewSVG {...pr} />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '20px',
            textAlign: 'center',
            padding: '10px',
          }}
        >
          Aprire una pagina
        </div>
      )}
    </>
  );
};
