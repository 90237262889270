/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { Fieldset } from 'primereact/fieldset';
import { LeafProps, NodeProps, Treeview } from '@common/treeview';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api';
import { TagItemEditor } from '@components/document-editors/TagDocumentEditor/TagItemEditor';
import { Toast } from 'primereact/toast';
import { TreeLink } from '@components/tree-link/TreeLink';
import { deepCopy, findResourceById } from '@services/utils';
import {
  getPathReference,
  useTagDocumentDataUtils,
} from '@components/document-editors/TagDocumentEditor/useTagDocumentDataUtils';
import { useCallback, useMemo, useRef, useState } from 'react';
import AutoFocusDiv from '@common/AutoFocusDiv';

function documentDataToTreeView(documentData: Project.DocumentData, rename: string) {
  const recursivelyParseLevel = (levels?: Project.ResourceTreeElement[], parentId?: string) => {
    return levels?.map((level) => {
      const id = parentId ? `${parentId}.${level.data.id}` : level.data.id;

      const tagItem = documentData.resourceTree
        ? findResourceById(documentData.resourceTree, level.data.id)
        : undefined;

      const levelItem: NodeProps | LeafProps = {
        id,
        title: level.name,
        icon: 'pi',
        open: false,
        parentFound: false,
        found: false,
        hidden: false,
        editable: false,
        autoedit: level.data.id === rename,
      };

      if (tagItem.children) {
        levelItem.actions = [
          { icon: 'pi pi-plus', code: 'newTag' },
          { icon: 'pi pi-folder', code: 'newStructure' },
          { icon: 'pi pi-pencil', code: 'edit' },
          { icon: 'pi pi-times', code: 'deleteItem' },
        ];
      } else {
        levelItem.actions = [
          { icon: 'pi pi-pencil', code: 'edit' },
          { icon: 'pi pi-times', code: 'deleteItem' },
          { icon: 'pi pi-link', code: 'link' },
        ];
      }

      if (parentId) {
        levelItem.parentFound = true;
      }

      if (tagItem) {
        switch (tagItem.type) {
          case Project.TagType.Numeric:
          case Project.TagType.String:
            levelItem.icon += ' pi-tag';
            break;
          case Project.TagType.Method:
            levelItem.icon += ' pi-bolt';
            break;
          case Project.TagType.Struct:
            levelItem.icon += ' pi-table';
            levelItem.children = recursivelyParseLevel(level.children, id);

            break;
        }
      }

      return levelItem;
    });
  };

  // ToDo: Remove when Treeview will support leaf at first level (return child instead)
  return [
    {
      title: '\\',
      id: 'root',
      open: true,
      parentFound: false,
      found: false,
      hidden: false,
      editable: false,
      actions: [
        { icon: 'pi pi-plus', code: 'newTag' },
        { icon: 'pi pi-folder', code: 'newStructure' },
      ],
      children: recursivelyParseLevel(documentData.resourceTree) ?? [],
    },
  ];
}

export const TagDocumentEditor: DocumentEditorComponent = ({
  data: documentData,
  onDataChange: onDocumentDataChange,
  documentId,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const toast = useRef<Toast>();
  const linkOverlay = useRef<OverlayPanel>(null);

  const { createItem, removeItem, renameItem, moveItem } = useTagDocumentDataUtils({
    documentData,
    onDocumentDataChange,
    documentId: documentId.documentId,
  });

  const [selectedTagId, setSelectedTagId] = useState<string | undefined>();
  const [startResourcePoint, setStartResourcePoint] = useState<Project.ResourceLinkPoint | undefined>();

  const [rename, setRename] = useState<string>();

  const tagsTree = useMemo(() => documentDataToTreeView(documentData, rename), [documentData]);

  const { selectedTagItem, selectedResource } = useMemo(() => {
    const tagId = selectedTagId?.split('.').pop();

    return {
      selectedTagItem: documentData.resourceTree ? findResourceById(documentData.resourceTree, tagId) : null,
      selectedResource: tagId ? getPathReference(documentData.resourceTree, selectedTagId.split('.')) : null,
    };
  }, [documentData.metadata, documentData.resourceTree, selectedTagId, rename]);

  const handleTagChange = useCallback(
    (resource: Project.ResourceTreeElement) => {
      const _documentData = deepCopy(documentData);

      const ref = getPathReference(_documentData.resourceTree, selectedTagId.split('.'));
      if (ref) {
        ref.arrays = resource.arrays;
        ref.methodParams = resource.methodParams;
        ref.type = resource.type;
      }

      onDocumentDataChange({
        ..._documentData,
      });
    },
    [documentData, onDocumentDataChange, selectedTagId]
  );

  const notifyAction = (action: string, data: string, event: any) => {
    const dataObj = JSON.parse(data);

    switch (action) {
      case 'newTag':
      case 'newStructure':
        setShowSpinner(true);

        try {
          let newId = '';
          if (action === 'newTag') {
            newId = createItem(dataObj.id, 'tag');
          } else {
            newId = createItem(dataObj.id, 'structure');
          }
          setRename(newId);

          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Creato con successo',
          });
        } catch (ex) {
          toast.current.show({
            severity: 'error',
            summary: 'error',
            detail: ex.message,
          });
        }

        setShowSpinner(false);
        break;

      case 'deleteItem':
        setShowSpinner(true);

        removeItem(dataObj.id);

        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Rimosso con successo',
        });

        setShowSpinner(false);
        break;

      case 'edit':
        renameItem(dataObj.id, dataObj.newTitle);
        break;

      case 'select':
        setSelectedTagId(dataObj.id);
        break;

      case 'drag-&-drop':
        if (dataObj.hasOwnProperty('startId') && dataObj.hasOwnProperty('endId')) {
          try {
            moveItem(dataObj.startId, dataObj.endId);
          } catch (ex: any) {
            // nothing to do
          }
        }
        break;

      case 'link':
        // create a new linkPoint
        const dataObjIds: string[] = dataObj.id.split('.');
        if (dataObjIds.length > 0) {
          const paths: { idx: string }[] = [];
          dataObjIds.forEach((id) => {
            const newPath = { idx: findResourceById(documentData.resourceTree, id).name };
            paths.push(newPath);
          });
          setStartResourcePoint({ documentId, path: paths });
          linkOverlay.current.toggle(event);
        }
        break;
    }
  };

  const handleLinkChange = (resourceLinks: Project.ResourceLink[]) => {
    onDocumentDataChange({
      ...documentData,
      resourceLinks,
    });
  };

  return (
    <>
      <div style={{ display: 'grid', height: '100%', gridTemplateColumns: '1fr 2fr', padding: '1rem', gap: '1em' }}>
        <Fieldset legend="Tags" style={{ minHeight: '340px', height: '675px' }}>
          <div className="card flex flex-column">
            <Treeview
              json={JSON.stringify(tagsTree)}
              selectedItems={[selectedTagId]}
              notifyAction={notifyAction}
              utility={{ spinner: showSpinner }}
            />
          </div>
        </Fieldset>

        {selectedResource && selectedTagItem && selectedResource.type !== Project.TagType.Struct && (
          <Fieldset legend="Configuration" style={{ minHeight: '340px', maxHeight: '675px' }}>
            <TagItemEditor resource={selectedResource} onChange={handleTagChange} />
          </Fieldset>
        )}
      </div>

      <Toast ref={toast} />

      <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
        <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
          {startResourcePoint && (
            <TreeLink
              startResourcePoint={startResourcePoint}
              startResourcePinType={Project.PinType.InOut}
              resourceLinks={documentData.resourceLinks}
              onChangeData={handleLinkChange}
            />
          )}
        </AutoFocusDiv>
      </OverlayPanel>
    </>
  );
};
