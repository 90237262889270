import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { PropertiesConfig } from '@config/widget/interface';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { PropertyEditorOptions } from '../main/PropertyEditorComponent';
import { useRef } from 'react';
import { useWidgetPropsStore } from '@stores/widgetPropsStore';
import AutoFocusDiv from '@components/common/AutoFocusDiv';
import PropertyList, { insertItem } from '../main/PropertyListEditorComponent';

export interface MultiPropertyEditorProps extends PropertyEditorComponentProps {
  subProperties: PropertiesConfig;
  customOverlayPosition?: boolean;
}

let MultiPropertyEditorOverlayCounter = 0;

export const MultiPropertyEditor = ({
  subProperties,
  addResetChildrenFc,
  customOverlayPosition = false,
}: MultiPropertyEditorProps) => {
  const editingProperties = useWidgetPropsStore((state) => state.editingProperties);
  const widgetId = editingProperties.data?.['id'];

  const bt = useRef<Button>(null);
  const op = useRef<OverlayPanel>(null);
  const overlayId = useRef<string>('MultiPropertyEditorOverlay' + (++MultiPropertyEditorOverlayCounter).toString());

  const editorOptions: PropertyEditorOptions[] = [];

  function onShowOverlay() {
    /*
		alert('ok');
		let overlayElem = document.getElementById(overlayId.current);
		overlayElem.style.top = "100px";
		let a=0;  */
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onclickHandler(ev: any) {
    op.current.toggle(ev); /*
		let x = (ev as MouseEvent).pageX;
		let y = (ev as MouseEvent).pageY;
		let overlayElem = document.getElementById(overlayId.current);
		//overlayElem.style.top = (y.toString() + " px");

		*/
  }

  // eslint-disable-next-line array-callback-return
  Object.entries(subProperties.properties).map(([key, editorConfig]) => {
    insertItem(editorOptions, widgetId, key, editorConfig);
  });

  return (
    <>
      <Button
        ref={bt}
        style={{ width: '2.3rem', height: '2.3rem', fontSize: '1.3rem' }}
        icon="fa-solid fa-pencil"
        aria-label="Bookmark"
        outlined
        onClick={(e) => {
          onclickHandler(e);
        }}
      />
      <OverlayPanel
        ref={op}
        closeOnEscape
        dismissable={true}
        id={overlayId.current}
        onShow={onShowOverlay}
        className={customOverlayPosition ? 'multi-properties-overlay' : ''}
      >
        <AutoFocusDiv style={{ width: '300px' }} onBlur={() => op.current.hide()}>
          <PropertyList
            list={editorOptions}
            addResetChildFunction={addResetChildrenFc}
            editingProperties={editingProperties}
          />
        </AutoFocusDiv>
      </OverlayPanel>
    </>
  );
};

export default MultiPropertyEditor;
