/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
export namespace ProjectTree {
  export type Node = Folder | Project;

  export interface Folder {
    folder_id: string;
    name: string;
    children: Node[];
  }

  export interface Project {
    project_id: string;
    description: string;
  }

  export type RootFolder = Node[];

  export const RootFolderId = 0;

  export interface Manager {
    /**
     * Returns the project tree for the current domain
     */
    getProjectTree(): Promise<RootFolder>;

    /**
     * Creates a new folder within the project tree
     * @param name Name of the new folder
     * @param parentId ID of the parent folder (RootFolderId for the root folder)
     * @returns the ID of the new folder
     */
    createProjectFolder(name: string, parentId: string): Promise<string>;

    /**
     * Removes a folder from the project tree; the folder and its children must not contain any projects.
     * @param id ID of the folder to remove
     * @returns nothing on success
     */
    removeProjectFolder(id: string): Promise<void>;

    /**
     * Modifies an existing project folder
     * @param id ID of the folder to modify
     * @param name new name of the folder
     * @returns nothing on success
     */
    renameProjectFolder(id: string, name: string): Promise<void>;

    /**
     * Moves an existing folder within the project tree; the folder and its children must not contains any open projects.
     * @param id ID of the folder to move
     * @param parentId ID of the new parent folder (0 for the root folder)
     * @param position position within the new parent folder
     * @returns nothing on success
     */
    moveProjectFolder(id: string, parentId: string, position: number): Promise<void>;

    /**
     * Creates a new project in the current domain at the given position
     * @param description description of the project
     * @param parentId id of the parent folder in the project tree (0 for the root folder)
     * @throws EsaWebIpcRemoteError if the operation was not successful
     * @throws EsaWebIpcClientError if the call failed
     */
    createProject(description: string, parentId: string): Promise<string>;

    /**
     * Removes the specified project; the project must not be open in any clients.
     * @param id UUID of the project
     * @returns nothing on success
     * @throws EsaWebIpcRemoteError if the operation was not successful
     * @throws EsaWebIpcClientError if the call failed
     */
    removeProject(id: string): Promise<void>;

    /**
     * Edits properties of an existing project; the project must not be open in any clients.
     * @param id ID of the project
     * @param description new description of the project
     * @returns nothing on success
     */
    renameProject(id: string, description: string): Promise<void>;

    /**
     * Moves a project within the project tree; the project must not be open in any clients.
     * @param id ID of the project to move
     * @param parentId ID of the new parent folder (0 for the root folder)
     * @param position position within the new parent folder
     * @returns nothing on success
     */
    moveProject(id: string, parentId: string, position: number): Promise<void>;
  }
}
