import { AlignItems } from '../base-widget';
import { PropertiesManager } from '../properties-manager';
import { View } from './view-widget';
import { WidgetProperties } from '../widget';

export class Page extends View {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-page');
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'FullScreen':
          this.domElement.style.flexGrow = data ? '1' : null;
          break;
        case 'AlignPage':
          const parent = document.getElementById('screen-root') as HTMLElement;
          switch (data) {
            case AlignItems.Start:
              parent.style.justifyContent = 'start';
              break;
            case AlignItems.Middle:
              parent.style.justifyContent = 'center';
              break;
            case AlignItems.End:
              parent.style.justifyContent = 'end';
              break;
            default:
              parent.style.justifyContent = null;
          }
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }
    super.setProperties(properties);
  }
}
