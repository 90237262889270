import create from 'zustand';

interface LayersEditorState {
  isLayersEditorVisible: boolean;
  layersEditorWidgets: any[];
  setLayersEditorVisible: (visible: boolean) => void;
  setLayersEditorWidgets: (widgets: any[]) => void;
}

export const useLayersEditorStore = create<LayersEditorState>((set) => ({
  isLayersEditorVisible: false,
  layersEditorWidgets: [],
  setLayersEditorVisible: (visible: boolean) => set({ isLayersEditorVisible: visible }),
  setLayersEditorWidgets: (widgets: any[]) => set({ layersEditorWidgets: widgets }),
}));
