import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { Widget, WidgetProperties } from '../widget';
import { WidgetFactory } from '../widget-factory';

export class Svg extends SimpleWidget {
  private widgetList: Widget[] = [];
  private svgElement: SVGElement; // L'elemento SVG vero e proprio

  constructor(public domElement: HTMLElement) {
    super(domElement);

    // elemento SVG all'interno del div
    this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.svgElement.classList.add('fast-svg');
    this.domElement.appendChild(this.svgElement); // Inseriamo l'SVG nel div
  }

  attach(): void {
    this.domElement.classList.add('fast-view');
    this.domElement.classList.add('fast-view-empty');
    this.domElement.classList.add('fast-svg-container');
    super.attach();

    this.domElement.addEventListener('click', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseenter', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseleave', (e) => {
      e.stopPropagation();
    });
  }

  detach(): void {
    super.detach();
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      let data;
      if (key === 'children') {
        data = properties[key];
      } else {
        data = PropertiesManager.getData(properties, key);
      }

      switch (key) {
        case 'children':
          this.setChildren(data);
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }
    super.setProperties(properties);
  }

  setChildren(children: WidgetProperties[]): void {
    while (this.svgElement.firstChild) {
      this.svgElement.removeChild(this.svgElement.firstChild);
    }
    this.detachChildren();

    children.forEach((childProperties) => {
      const childWidget = WidgetFactory.buildWidget(childProperties.type);
      childWidget.setProperties(childProperties);
      childWidget.attach();

      if (childWidget.domElement instanceof HTMLDivElement) {
        this.domElement.appendChild(childWidget.domElement);
      } else if (childWidget.domElement instanceof SVGElement) {
        this.svgElement.appendChild(childWidget.domElement);
      }

      this.widgetList.push(childWidget);
    });
  }

  detachChildren(): void {
    this.widgetList.forEach((widget) => widget.detach());
    this.widgetList = [];
  }
}
