import { AlignItems } from '../base-widget';
import { PropertiesManager } from '../properties-manager';
import { WidgetContainer } from '../widget-container';
import { WidgetProperties } from '../widget';

export enum FlexboxDirection {
  Left = 0,
  Top = 1,
  Right = 2,
  Bottom = 3,
}

export enum JustifyContent {
  Start = 0,
  Middle = 1,
  End = 2,
  Stretch = 3,
}

export class View extends WidgetContainer {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-view');
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);
      switch (key) {
        case 'JustifyContent':
          this.setEnumProperty(data, 'justifyContent', {
            [AlignItems.Start]: 'start',
            [AlignItems.Middle]: 'center',
            [AlignItems.End]: 'end',
            [AlignItems.Stretch]: 'space-evenly',
          });
          break;
        case 'AlignItems':
          this.setEnumProperty(data, 'alignItems', {
            [AlignItems.Start]: 'flex-start',
            [AlignItems.Middle]: 'center',
            [AlignItems.End]: 'end',
            [AlignItems.Stretch]: 'stretch',
          });
          break;
        case 'FlexDirection':
          this.setEnumProperty(data, 'flexDirection', {
            [FlexboxDirection.Left]: 'row-reverse',
            [FlexboxDirection.Top]: 'column-reverse',
            [FlexboxDirection.Right]: 'row',
            [FlexboxDirection.Bottom]: 'column',
          });
          break;
          break;
        case 'Wrap':
          this.domElement.style.flexWrap = data ? 'wrap' : null;
          break;
        case 'Gaps':
          this.setCompositeProperty(data, { column: 'columnGap', row: 'rowGap' }, true);
          break;
        case 'Padding':
          this.setCompositeProperty(
            data,
            { top: 'paddingTop', left: 'paddingLeft', right: 'paddingRight', bottom: 'paddingBottom' },
            true
          );
          break;
        default:
          this.setDefaultProperties(properties);
      }
    }
    super.setProperties(properties);
  }
}
