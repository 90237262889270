import { DocumentEditorFactory } from '@services/documentEditorFactory';
import { documentEditorsConfig } from '@config/documentEditor';
import { ipcClient, useIpcStore, webClient } from '@stores/ipcStore';
import React, { useEffect, useState } from 'react';

documentEditorsConfig.forEach((config) => {
  DocumentEditorFactory.registerDocumentBuilder(config.type, config.editor);
});

interface Props {
  children: React.ReactNode;
}

export const IpcClientConnector: React.FC<Props> = ({ children }) => {
  const [connected, setConnected] = useState(false);

  // mc*** da ridefinire per capire come riconnettersi. questo codice arriva dal test
  webClient.setReconnectCallback(() => {
    console.log('Attempting to reconnect...');
    return true; // Returning true means the client should attempt to reconnect
  });

  useEffect(() => {
    (async () => {
      await ipcClient.connectWithServer(''); // mc*** da rimuovere. lasciare solo webClient
      useIpcStore.getState().registerEvents();
      webClient.connect();

      setTimeout(() => setConnected(true), 500);
    })();
  }, []);

  if (connected) {
    return <>{children}</>;
  } else {
    return (
      <div id="spinner-container">
        <div className="logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="200" height="200">
            <rect x="5" y="5" width="40" height="40" rx="8" ry="8" fill="#333" stroke="white" strokeWidth="2" />
            <path fill="white" d="M22.5,10 L27.5,20 H17.5 L22.5,30 L17.5,40 H27.5 L22.5,25 H32.5 L22.5,10 Z" />
          </svg>
        </div>
        <div className="spinner"></div>
      </div>
    );
  }
};
