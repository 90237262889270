import { AuthClient, AuthData } from '../types/auth_client';
import { DatabaseAPI, DatabaseInfos } from '../types/database';
import { DeployData, TestingAPI } from '../types/testing';
import { EventDispatcher, EventHandler } from '../types/event_handling';
import { Panel, PanelManager, SyncStats } from '../types/panels';
import { Project } from '../types/project';
import { ProjectEditor } from '../types/project_editor';
import { ProjectManager } from '../types/project_manager';
import { ProjectTree } from '../types/project_tree';
import { Resource, ResourceManager, ResourceType } from '../types/resources';
import { RpcClient } from '@esa-fast/rpc-client';
import { User } from '../types/user';
import { UserManager } from '../types/user_manager';

export class EsaWebRpcClient extends RpcClient implements AuthClient, ProjectTree.Manager, ProjectEditor {
  /* UserManager,
    ProjectManager,
    PanelManager,
    ResourceManager,
    TestingAPI,
    EventDispatcher,
    DatabaseAPI */
  webClient = null;
  session_token = ''; // mc*** ricordarsi di rimettere '' durante il logout
  authData?: AuthData;

  setSessionToken(newToken: string) {
    this.session_token = newToken;
  }

  // just forward arguments to the superclass
  constructor(url: string, options: { keepAliveInterval?: number } = {}) {
    super(url, 2000000);

    // create RpcClient
    this.webClient = new RpcClient(url, 2000000);

    // register a default no-op handler
    this.setEventHandler({});
  }

  // Event handling
  setEventHandler(handler: EventHandler): void {
    const eh = handler as any;
    this.addHandler('event', async (args) => {
      try {
        const evType = args['type'];
        if (!eh[evType]) {
          console.log(`Ignoring unhandled event ${evType}`);
          return;
        }
        eh[evType](args['args']);
      } catch (e) {
        console.error(e);
      }
    });
  }

  // Authentication
  getAuthData(): AuthData | undefined {
    return this.authData;
  }

  async authenticate(domain: string, username: string, password: string): Promise<AuthData> {
    const params = {
      domain_id: domain,
      username,
      password,
    };

    this.authData = (await this.call('login', params)) as AuthData;
    return this.authData;
  }

  // User management
  /* async listUsers(filter?: string): Promise<Array<User>> {
    const args: any = {};
    if (filter) args.filter = filter;
    return await this.call('listUsers', args);
  }

  async getUser(username: string): Promise<User> {
    return await this.call('getUser', { username: username });
  }

  async createUser(user: User): Promise<User> {
    return await this.call('createUser', user);
  }

  async editUser(user: User): Promise<User> {
    return await this.call('editUser', user);
  }

  async removeUser(username: string): Promise<void> {
    await this.call('removeUser', { username: username });
  } */

  // Project tree manager
  async getProjectTree(): Promise<ProjectTree.RootFolder> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
    };
    return await this.call('getProjectTree', params);
  }

  async createProjectFolder(name: string, parentId: string): Promise<string> {
    const params = {
      session_token: this.session_token,
      parent_id: parentId,
      name: name,
      domain_id: this.authData.domain.id,
    };
    return await this.call('createProjectFolder', params);
  }

  async removeProjectFolder(id: string): Promise<void> {
    // await this.call('removeProjectFolder', { id: id });
    throw new Error('Method not implemented.');
  }

  async renameProjectFolder(id: string, name: string): Promise<void> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      folder_id: id,
      new_name: name,
    };
    return await this.call('renameProjectFolder', params);
  }

  async moveProjectFolder(id: string, parentId: string, position: number): Promise<void> {
    // await this.call('moveProjectFolder', { id: id, parentId: parentId, position: position });
    throw new Error('Method not implemented.');
  }

  async createProject(description: string, parentId: string): Promise<string> {
    const params = {
      session_token: this.session_token,
      parent_id: parentId,
      description: description,
      domain_id: this.authData.domain.id,
    };
    return await this.call('createProject', params);
  }

  async removeProject(id: string): Promise<void> {
    // await this.call('removeProject', { id: id });
    throw new Error('Method not implemented.');
  }

  async renameProject(id: string, description?: string): Promise<void> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      project_id: id,
      new_description: description,
    };
    return await this.call('renameProject', params);
  }

  async moveProject(id: string, parentId: string, position: number): Promise<void> {
    // await this.call('moveProject', { id: id, parentId: parentId, position: position });
    throw new Error('Method not implemented.');
  }

  // Project manager

  async openProject(id: string): Promise<Project.Project> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      project_id: id,
    };
    return await this.call('openProject', params);
  }

  /* async closeProject(id: string): Promise<void> {
    await this.call('closeProject', { id: id });
  }

  async getProjectStructure(id: string): Promise<Project.ProjectStructure> {
    return await this.call('getProjectStructure', { id: id });
  } */

  // Project editor

  async createFolder(projectId: string, name: string, parentId: string): Promise<string> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      project_id: projectId,
      name,
      parent_id: parentId,
    };
    return await this.call('createFolder', params);
  }

  async removeFolder(nodeId: Project.ProjectNodeKey): Promise<void> {
    // return await this.call('removeFolder', { projectId: projectId, domainId: domainId });
    throw new Error('Method not implemented.');
  }
  async createDocument(projectId: string, type: string, name: string, parentId: string): Promise<string> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      project_id: projectId,
      type,
      name,
      parent_id: parentId,
    };
    return await this.call('createDocument', params);
  }

  async removeDocument(nodeId: Project.ProjectNodeKey): Promise<void> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      project_id: nodeId.projectId,
      document_id: nodeId.documentId,
    };
    return await this.call('removeDocument', params);
  }

  async moveNode(
    nodeId: Project.ProjectNodeKey,
    destinationId: Project.ProjectNodeKey,
    position: number
  ): Promise<void> {
    /* return await this.call('moveNode', {
      projectId: projectId,
      domainId: domainId,
      parentId: parentId,
      position: position,
    }); */
    throw new Error('Method not implemented.');
  }

  async renameNode(nodeId: Project.ProjectNodeKey, name: string): Promise<void> {
    // return await this.call('renameNode', { projectId: projectId, domainId: domainId, name: name });
    throw new Error('Method not implemented.');
  }

  async openDocument(nodeId: Project.ProjectNodeKey): Promise<Project.OpenedDocument> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      project_id: nodeId.projectId,
      document_id: nodeId.documentId,
    };
    return await this.call('openDocument', params);
  }

  async updateOpenedDocument(nodeId: Project.ProjectNodeKey, data: Project.DocumentData): Promise<void> {
    const params = {
      session_token: this.session_token,
      domain_id: this.authData.domain.id,
      project_id: nodeId.projectId,
      document_id: nodeId.documentId,
      document_data: data,
    };
    return await this.call('updateOpenedDocument', params);
  }

  async updateEditorStatus(nodeId: Project.ProjectNodeKey, status: unknown): Promise<void> {
    // return await this.call('updateEditorStatus', { projectId: projectId, domainId: domainId, status: status });
    throw new Error('Method not implemented.');
  }

  async saveOpenedDocument(nodeId: Project.ProjectNodeKey): Promise<void> {
    // return await this.call('saveOpenedDocument', { projectId: projectId, domainId: domainId });
    throw new Error('Method not implemented.');
  }

  async resetModifiedFlag(nodeId: Project.ProjectNodeKey): Promise<void> {
    // return await this.call('resetModifiedFlag', { projectId: projectId, domainId: domainId });
    throw new Error('Method not implemented.');
  }

  async closeOpenedDocument(nodeId: Project.ProjectNodeKey): Promise<void> {
    // return await this.call('closeOpenedDocument', { projectId: projectId, domainId: domainId });
    throw new Error('Method not implemented.');
  }

  /* async listProjectPanels(projectId: string): Promise<Panel[]> {
    return await this.call('listProjectPanels', { projectId: projectId });
  }

  async addPanelToProject(
    projectId: string,
    id: { serial?: string; connUri?: string },
    projectRole?: string
  ): Promise<string> {
    const args: any = {
      projectId: projectId,
      ...id,
    };
    if (projectRole !== undefined) args.projectRole = projectRole;
    return await this.call('addPanelToProject', args);
  }

  async editProjectPanel(
    projectId: string,
    panelId: string,
    what: { newConnUri?: string; newRole?: string }
  ): Promise<void> {
    return await this.call('editProjectPanel', { project: projectId, panelId: panelId, ...what });
  }

  async removePanelFromProject(projectId: string, panelId: string): Promise<void> {
    return await this.call('removePanelFromProject', { projectId: projectId, panelId: panelId });
  }

  async retryPanelConnection(panelId: string): Promise<void> {
    return await this.call('retryPanelConnection', { panelId: panelId });
  }

  async connectToPanel(host: string, port: number, options: { path?: string; timeout?: number } = {}): Promise<void> {
    return await this.call('connectToPanel', { host: host, port: port, ...options });
  }

  async deployToPanels(projectId: string, panelIds: string[], test_data?: any): Promise<void> {
    const args: any = {
      projectId: projectId,
      panelIds: panelIds,
    };
    if (test_data) args.test_data = test_data;
    return await this.call('deployToPanels', args, 30000);
  }

  async getDeployData(projectId: string, role: string): Promise<DeployData> {
    return await this.call('getDeployData', { projectId: projectId, role: role });
  }

  async syncResources(projectId: string, serial: string): Promise<SyncStats> {
    return await this.call('syncResources', { projectId: projectId, serial: serial }, 30000);
  }

  private to_resource(data: any): Resource {
    return {
      id: data.id,
      type: data.type as ResourceType,
      label: data.label,
      size: data.size,
      hash: data.hash,
      editorUri: `${origin}/r/${data.filename}`,
    };
  }

  async listResources(
    projectId: string,
    options: { typeFilter?: ResourceType; nameFilter?: string } = {}
  ): Promise<Resource[]> {
    const res: any[] = await this.call('resourceList', { projectId: projectId, ...options });
    return res.map(this.to_resource);
  }

  async removeResource(projectId: string, id: number): Promise<void> {
    return await this.call('resourceRemove', { projectId: projectId, id: id });
  }

  async createResource(projectId: string, type: ResourceType, contents: File, label?: string): Promise<Resource> {
    const args: FT.sendResource_args = {
      projectId: projectId,
      type: type,
      label: label,
      contents: contents,
    };

    return await FT.sendResource(this, args);
  }

  async updateResource(projectId: string, id: number, what: { label?: string; contents?: File }): Promise<Resource> {
    if (!(what.label || what.contents)) throw new EsaWebIpcClientError('Nothing to do');

    const args: FT.sendResource_args = {
      projectId: projectId,
      id: id,
      ...what,
    };

    return await FT.sendResource(this, args);
  } 
    
  getDatabaseStructureData(connectionString: string): Promise<DatabaseInfos> {
    throw new Error('Method not implemented.');
  }
    */
}
