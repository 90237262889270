import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetDictionary } from '../widget-dictionary';
import { WidgetProperties } from '../widget';

export class SvgExternal extends SimpleWidget {
  private externalId: string | null = null;
  private rotationCenterX: number | null = null;
  private rotationCenterY: number | null = null;

  constructor(public domElement: HTMLDivElement) {
    super(domElement);

    // Contenitore HTML div per l'SVG
    this.domElement = document.createElement('div');
    this.domElement.classList.add('fast-svg-external-container');
    this.domElement.classList.add('svg-external');
    this.domElement.setAttribute('data-resizable', 'true');

    //placeholder default
    const parser = new DOMParser();
    const defaultXMLString = `<svg xmlns="http://www.w3.org/2000/svg" width="680.764" height="528.354" viewBox="0 0 180.119 139.794"><g transform="translate(-13.59 -66.639)" paint-order="fill markers stroke"><path fill="#d0d0d0" d="M13.591 66.639H193.71v139.794H13.591z"/><path d="m118.507 133.514-34.249 34.249-15.968-15.968-41.938 41.937H178.726z" opacity=".675" fill="#fff"/><circle cx="58.217" cy="108.555" r="11.773" opacity=".675" fill="#fff"/><path fill="none" d="M26.111 77.634h152.614v116.099H26.111z"/></g></svg>`;

    const svgDocument = parser.parseFromString(defaultXMLString, 'image/svg+xml');
    const svgElement = svgDocument.documentElement;
    svgElement.setAttribute('width', '100%');
    svgElement.setAttribute('height', '100%');

    // Aggiungiamo l'SVG come child del div
    this.domElement.appendChild(svgElement);
  }

  attach(): void {
    const container = document.getElementById('svg-container');
    if (container) {
      container.appendChild(this.domElement);
    }
    this.domElement.setAttribute('draggable', 'true');
    this.domElement.addEventListener('mouseenter', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseleave', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('dragstart', (event: DragEvent) => {
      event.dataTransfer?.setData('text/plain', this.domElement.id);
    });
  }

  detach(): void {
    if (this.domElement.parentElement) {
      this.domElement.parentElement.removeChild(this.domElement);
    }

    if (this.externalId) {
      WidgetDictionary.unregisterWidget(this.externalId);
    }
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      const data = PropertiesManager.getData(properties, key);

      switch (key) {
        case 'id':
          this.externalId = data;
          this.domElement.id = data;
          WidgetDictionary.registerWidget(data, this);
          break;
        case 'svg_children':
          const children = data.children;
          children.forEach((element) => {
            WidgetDictionary.unregisterWidget(element.id);
            WidgetDictionary.registerWidget(element.id, this);
          });
          break;
        default:
          this.setDefaultProperties(properties);
      }
      this.applyProperty(key, data);
    }
    super.setProperties(properties);
  }

  private applyProperty(key: string, value: any): void {
    const svgElement = this.domElement.querySelector('svg');
    let angle, container;
    switch (key) {
      case 'x':
        this.domElement.style.left = value + 'px';

        //controllo bordi
        container = this.domElement.parentElement;
        if (container) {
          const draggedWidgetRect = this.domElement.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          const currentLeft = parseFloat(this.domElement.style.left) || 0;

          //bordo sinistro
          if (draggedWidgetRect.x < containerRect.x) {
            this.domElement.style.left = `${currentLeft + (containerRect.x - draggedWidgetRect.x)}px`;
          }
          //bordo destro
          if (draggedWidgetRect.x + draggedWidgetRect.width > containerRect.right) {
            this.domElement.style.left = `${
              currentLeft - (draggedWidgetRect.x + draggedWidgetRect.width - containerRect.right)
            }px`;
          }
        }
        break;
      case 'y':
        this.domElement.style.top = value + 'px';

        //controllo bordi
        container = this.domElement.parentElement;
        if (container) {
          const draggedWidgetRect = this.domElement.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          const currentTop = parseFloat(this.domElement.style.top) || 0;

          //bordo alto
          if (draggedWidgetRect.y < containerRect.y) {
            this.domElement.style.top = `${currentTop + (containerRect.y - draggedWidgetRect.y)}px`;
          }

          //bordo inferiore
          if (draggedWidgetRect.y + draggedWidgetRect.height > containerRect.bottom) {
            this.domElement.style.top = `${
              currentTop - (draggedWidgetRect.y + draggedWidgetRect.height - containerRect.bottom)
            }px`;
          }
        }
        break;
      case 'width':
        if (value && value > 0) {
          this.domElement.style.width = value + 'px';
        }
        break;
      case 'height':
        if (value && value > 0) {
          this.domElement.style.height = value + 'px';
        }
        break;
      case 'svgUrl':
        if (value.svgContent) {
          if (svgElement) {
            this.domElement.removeChild(svgElement);
          }

          // Parsing del contenuto SVG
          const parser = new DOMParser();
          const svgDocument = parser.parseFromString(value.svgContent, 'image/svg+xml');
          const newElement = svgDocument.documentElement;

          newElement.setAttribute('width', '100%');
          newElement.setAttribute('height', '100%');

          // Aggiungiamo l'SVG come child del div
          this.domElement.appendChild(newElement);
        }
        break;
      case 'rotationAngle':
        if (this.rotationCenterX === undefined || this.rotationCenterX === null) {
          this.rotationCenterX = this.domElement.getBoundingClientRect().width / 2;
        }
        if (this.rotationCenterY === undefined || this.rotationCenterY === null) {
          this.rotationCenterY = this.domElement.getBoundingClientRect().height / 2;
        }
        // Applica la rotazione
        this.applyRotation(value !== undefined ? value : 0);
        break;
      case 'rotationCenterX':
        this.rotationCenterX = value !== undefined ? value : this.domElement.getBoundingClientRect().width / 2;
        angle = this.getRotationAngle(this.domElement);
        this.domElement.style.transform = `rotate(${0}deg)`;
        this.applyRotationCenterX(value);
        this.domElement.style.transform = `rotate(${angle}deg)`;
        break;
      case 'rotationCenterY':
        this.rotationCenterY = value !== undefined ? value : this.domElement.getBoundingClientRect().height / 2;
        angle = this.getRotationAngle(this.domElement);
        this.domElement.style.transform = `rotate(${0}deg)`;
        this.applyRotationCenterY(value);
        this.domElement.style.transform = `rotate(${angle}deg)`;
        break;
      default:
        break;
    }
  }

  private applyRotation(rotation: number): void {
    const elementRect = this.domElement.getBoundingClientRect();
    let centerX = this.rotationCenterX ? this.rotationCenterX : elementRect.width / 2;
    let centerY = this.rotationCenterY ? this.rotationCenterY : elementRect.height / 2;

    //ho centro di rotazione custom
    if (this.rotationCenterX && this.rotationCenterY) {
      centerX = this.rotationCenterX;
      centerY = this.rotationCenterY;
    } else {
      centerX = elementRect.width / 2;
      centerY = elementRect.height / 2;
    }

    // Applica la trasformazione di rotazione all'intero div
    this.domElement.style.transform = `rotate(${rotation}deg)`;
    this.domElement.style.transformOrigin = `${centerX}px ${centerY}px`;
  }

  private applyRotationCenterX(x: number): void {
    const rotationCenter = this.domElement.querySelector('.rotation-center') as HTMLElement;
    //applica graficamente SOLO se visibile
    if (rotationCenter !== null && rotationCenter !== undefined) {
      if (x !== undefined) {
        rotationCenter.style.left = `${x}px`;
      } else {
        rotationCenter.style.left = this.domElement.getBoundingClientRect().width / 2 + 'px';
        rotationCenter.style.transform = 'translate(-50%, -50%)';
      }
    }
  }

  private applyRotationCenterY(y: number): void {
    const rotationCenter = this.domElement.querySelector('.rotation-center') as HTMLElement;
    //applica graficamente SOLO se visibile
    if (rotationCenter !== null && rotationCenter !== undefined) {
      if (y !== undefined) {
        rotationCenter.style.top = `${y}px`;
      } else {
        rotationCenter.style.top = this.domElement.getBoundingClientRect().height / 2 + 'px';
        rotationCenter.style.transform = 'translate(-50%, -50%)';
      }
    }
  }

  private getRotationAngle(element: HTMLElement): number {
    let transform = window.getComputedStyle(element).getPropertyValue('transform');

    if (transform === 'none' || transform === '') {
      transform = this.domElement.style.transform;
      if (transform !== '' && transform !== undefined) {
        const match = transform.match(/rotate\(([-\d.]+)deg\)/);
        if (match) {
          return parseFloat(match[1]);
        }
      } else {
        return 0;
      }
    }

    const values = transform.split('(')[1].split(')')[0].split(',');
    const a = parseFloat(values[0]);
    const b = parseFloat(values[1]);

    //angolo in gradi
    const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));

    return angle;
  }
}
