import { PropertiesManager } from '../properties-manager';
import { SimpleWidget } from '../simple-widget';
import { WidgetDictionary } from '../widget-dictionary';
import { WidgetProperties } from '../widget';

export class SvgText extends SimpleWidget {
  private textId: string | null = null;
  private textElement: SVGTextElement;
  private svgElement: SVGElement;
  private rotationCenterX: number | null = null;
  private rotationCenterY: number | null = null;

  constructor(public domElement: HTMLDivElement) {
    super(domElement);

    // Contenitore HTML div per il testo SVG
    this.domElement = document.createElement('div');
    this.domElement.classList.add('fast-svg-text-container');
    this.domElement.classList.add('svg-text');
    this.domElement.setAttribute('data-resizable', 'true');

    // elemento SVG all'interno del div
    this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.svgElement.setAttribute('width', '100%');
    this.svgElement.setAttribute('height', '100%');

    // elemento SVG <text>
    this.textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    this.textElement.setAttribute('x', '0');
    this.textElement.setAttribute('y', '24');
    this.textElement.setAttribute('font-size', '24');
    this.textElement.setAttribute('fill', 'black');
    this.textElement.textContent = 'Testo di base';
    this.svgElement.appendChild(this.textElement);
    this.domElement.appendChild(this.svgElement);
  }

  private getSvgCoordinates(event: DragEvent) {
    const svgRect = this.domElement.getBoundingClientRect();
    const x = event.clientX - svgRect.left;
    const y = event.clientY - svgRect.top;
    return { x, y };
  }

  attach(): void {
    const container = document.getElementById('svg-container');
    if (container) {
      container.appendChild(this.domElement);
    }
    this.domElement.addEventListener('mouseenter', (e) => {
      e.stopPropagation();
    });

    this.domElement.addEventListener('mouseleave', (e) => {
      e.stopPropagation();
    });
    this.domElement.setAttribute('draggable', 'true');

    this.domElement.addEventListener('dragstart', (event: DragEvent) => {
      event.dataTransfer?.setData('text/plain', this.domElement.id);
    });
  }

  detach(): void {
    super.detach();

    if (this.domElement.parentElement) {
      this.domElement.parentElement.removeChild(this.domElement);
    }

    if (this.textId) {
      WidgetDictionary.unregisterWidget(this.textId);
    }
  }

  setProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      const data = PropertiesManager.getData(properties, key);

      if (this.textElement.id) {
        this.textElement = document.getElementById(this.textElement.id) as unknown as SVGTextElement;
      }
      switch (key) {
        case 'id':
          this.textId = data;
          this.domElement.id = data;
          WidgetDictionary.registerWidget(data, this);
          break;
        default:
          this.setDefaultProperties(properties);
      }
      this.applyProperty(key, data);
    }
    super.setProperties(properties);
  }

  private applyProperty(key: string, value: any): void {
    let angle, container;
    switch (key) {
      case 'x':
        this.domElement.style.left = value + 'px';

        //controllo bordi
        container = this.domElement.parentElement;
        if (container) {
          const draggedWidgetRect = this.domElement.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          const currentLeft = parseFloat(this.domElement.style.left) || 0;

          //bordo sinistro
          if (draggedWidgetRect.x < containerRect.x) {
            this.domElement.style.left = `${currentLeft + (containerRect.x - draggedWidgetRect.x)}px`;
          }
          //bordo destro
          if (draggedWidgetRect.x + draggedWidgetRect.width > containerRect.right) {
            this.domElement.style.left = `${
              currentLeft - (draggedWidgetRect.x + draggedWidgetRect.width - containerRect.right)
            }px`;
          }
        }
        break;
      case 'y':
        this.domElement.style.top = value + 'px';

        //controllo bordi
        container = this.domElement.parentElement;
        if (container) {
          const draggedWidgetRect = this.domElement.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          const currentTop = parseFloat(this.domElement.style.top) || 0;

          //bordo alto
          if (draggedWidgetRect.y < containerRect.y) {
            this.domElement.style.top = `${currentTop + (containerRect.y - draggedWidgetRect.y)}px`;
          }

          //bordo inferiore
          if (draggedWidgetRect.y + draggedWidgetRect.height > containerRect.bottom) {
            this.domElement.style.top = `${
              currentTop - (draggedWidgetRect.y + draggedWidgetRect.height - containerRect.bottom)
            }px`;
          }
        }
        break;
      case 'width':
        if (value && value > 0) {
          this.domElement.style.width = value + 'px';
        }
        break;
      case 'height':
        if (value && value > 0) {
          this.domElement.style.height = value + 'px';
        }
        break;
      case 'fontSize':
        this.textElement.setAttribute('font-size', value.toString());
        this.adjustDivSize(); // Aggiorna la dimensione del div in base alla dimensione del testo
        break;
      case 'fill':
        this.textElement.setAttribute('fill', value.toString());
        break;
      case 'text':
        this.textElement.textContent = value;
        this.adjustDivSize();
        break;
      case 'rotationAngle':
        if (this.rotationCenterX === undefined || this.rotationCenterX === null) {
          this.rotationCenterX = this.domElement.getBoundingClientRect().width / 2;
        }
        if (this.rotationCenterY === undefined || this.rotationCenterY === null) {
          this.rotationCenterY = this.domElement.getBoundingClientRect().height / 2;
        }
        // Applica la rotazione
        this.applyRotation(value !== undefined ? value : 0);
        break;
      case 'rotationCenterX':
        this.rotationCenterX = value !== undefined ? value : this.domElement.getBoundingClientRect().width / 2;
        angle = this.getRotationAngle(this.domElement);
        this.domElement.style.transform = `rotate(${0}deg)`;
        this.applyRotationCenterX(value);
        this.domElement.style.transform = `rotate(${angle}deg)`;
        break;
      case 'rotationCenterY':
        this.rotationCenterY = value !== undefined ? value : this.domElement.getBoundingClientRect().height / 2;
        angle = this.getRotationAngle(this.domElement);
        this.domElement.style.transform = `rotate(${0}deg)`;
        this.applyRotationCenterY(value);
        this.domElement.style.transform = `rotate(${angle}deg)`;
        break;
      default:
        break;
    }
  }

  private applyRotation(rotation: number): void {
    const elementRect = this.domElement.getBoundingClientRect();
    let centerX = this.rotationCenterX ? this.rotationCenterX : elementRect.width / 2;
    let centerY = this.rotationCenterY ? this.rotationCenterY : elementRect.height / 2;

    //ho centro di rotazione custom
    if (this.rotationCenterX && this.rotationCenterY) {
      centerX = this.rotationCenterX;
      centerY = this.rotationCenterY;
    } else {
      centerX = elementRect.width / 2;
      centerY = elementRect.height / 2;
    }

    // Applica la trasformazione di rotazione all'intero div
    this.domElement.style.transform = `rotate(${rotation}deg)`;
    this.domElement.style.transformOrigin = `${centerX}px ${centerY}px`;
  }

  private applyRotationCenterX(x: number): void {
    const rotationCenter = this.domElement.querySelector('.rotation-center') as HTMLElement;
    //applica graficamente SOLO se visibile
    if (rotationCenter !== null && rotationCenter !== undefined) {
      if (x !== undefined) {
        rotationCenter.style.left = `${x}px`;
      } else {
        rotationCenter.style.left = this.domElement.getBoundingClientRect().width / 2 + 'px';
        rotationCenter.style.transform = 'translate(-50%, -50%)';
      }
    }
  }

  private applyRotationCenterY(y: number): void {
    const rotationCenter = this.domElement.querySelector('.rotation-center') as HTMLElement;
    //applica graficamente SOLO se visibile
    if (rotationCenter !== null && rotationCenter !== undefined) {
      if (y !== undefined) {
        rotationCenter.style.top = `${y}px`;
      } else {
        rotationCenter.style.top = this.domElement.getBoundingClientRect().height / 2 + 'px';
        rotationCenter.style.transform = 'translate(-50%, -50%)';
      }
    }
  }

  private getRotationAngle(element: HTMLElement): number {
    let transform = window.getComputedStyle(element).getPropertyValue('transform');

    if (transform === 'none' || transform === '') {
      transform = this.domElement.style.transform;
      if (transform !== '' && transform !== undefined) {
        const match = transform.match(/rotate\(([-\d.]+)deg\)/);
        if (match) {
          return parseFloat(match[1]);
        }
      } else {
        return 0;
      }
    }

    const values = transform.split('(')[1].split(')')[0].split(',');
    const a = parseFloat(values[0]);
    const b = parseFloat(values[1]);

    //angolo in gradi
    const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));

    return angle;
  }

  private adjustDivSize(): void {
    requestAnimationFrame(() => {
      if (this.textElement.id) {
        this.textElement = document.getElementById(this.textElement.id) as unknown as SVGTextElement;
        const bbox = this.textElement.getBBox(); // Ottieni le dimensioni del testo SVG
        this.domElement.style.width = `${bbox.width + 20}px`; // Aggiungi un margine per contenere il testo
        this.domElement.style.height = `${bbox.height + 20}px`; // Aggiungi un margine per contenere il testo
      }
    });
  }
}
