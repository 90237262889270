import './style.css';
import { Accordion } from './concrete-widgets/accordion-widget';
import { Button } from './concrete-widgets/button-widget';
import { Checkbox } from './concrete-widgets/checkbox-widget';
import { FrameHTML } from './concrete-widgets/framehtml-widget';
import { Image } from './concrete-widgets/image-widget';
import { ImageList } from './concrete-widgets/imagelist-widget';
import { Label } from './concrete-widgets/label-widget';
import { ListBox } from './concrete-widgets/listbox-widget';
import { Page } from './concrete-widgets/page-widget';
import { RadioButton } from './concrete-widgets/radiobutton-widget';
import { RootWidget } from './root-widget';
import { Slider } from './concrete-widgets/slider-widget';
import { Svg } from './concrete-widgets/svg-widget';
import { SvgExternal } from './concrete-widgets/svgexternal-widget';
import { SvgRect } from './concrete-widgets/svgrect-widget';
import { SvgText } from './concrete-widgets/svgtext-widget';
import { Table } from './concrete-widgets/table-widget';
import { Tabs } from './concrete-widgets/tabs-widget';
import { Textbox } from './concrete-widgets/textbox-widget';
import { VideoPlayer } from './concrete-widgets/videoplayer-widget';
import { View } from './concrete-widgets/view-widget';
import { WidgetDictionary } from './widget-dictionary';
import { WidgetFactory } from './widget-factory';
import { WidgetProperties } from './widget';

export interface NewWidget {
  type: string;
  properties: WidgetProperties;
}

export class WidgetHandler {
  constructor(public root: HTMLElement) {
    WidgetDictionary.registerWidget('root', new RootWidget(root));
    WidgetFactory.registerBuilder('page', () => new Page());
    WidgetFactory.registerBuilder('view', () => new View());
    WidgetFactory.registerBuilder('button', () => new Button(document.createElement('div')));
    WidgetFactory.registerBuilder('label', () => new Label(document.createElement('div')));
    WidgetFactory.registerBuilder('textBox', () => new Textbox(document.createElement('input')));
    WidgetFactory.registerBuilder('videoPlayer', () => new VideoPlayer(document.createElement('video')));
    WidgetFactory.registerBuilder('checkbox', () => new Checkbox(document.createElement('div')));
    WidgetFactory.registerBuilder('radio', () => new RadioButton(document.createElement('div')));
    WidgetFactory.registerBuilder('frameHtml', () => new FrameHTML(document.createElement('iframe')));
    WidgetFactory.registerBuilder('slider', () => new Slider(document.createElement('div')));
    WidgetFactory.registerBuilder('listBox', () => new ListBox(document.createElement('div')));
    WidgetFactory.registerBuilder('accordion', () => new Accordion());
    WidgetFactory.registerBuilder('svg', () => new Svg(document.createElement('div')));
    WidgetFactory.registerBuilder('svgText', () => new SvgText(document.createElement('div')));
    WidgetFactory.registerBuilder('svgRect', () => new SvgRect(document.createElement('div')));
    WidgetFactory.registerBuilder('svgExternal', () => new SvgExternal(document.createElement('div')));
    WidgetFactory.registerBuilder('tabs', () => new Tabs());
    WidgetFactory.registerBuilder('table', () => new Table());
    WidgetFactory.registerBuilder('image', () => new Image(document.createElement('div')));
    WidgetFactory.registerBuilder('imageList', () => new ImageList(document.createElement('div')));
  }

  setProperties(id: string, properties: WidgetProperties) {
    const widget = WidgetDictionary.findWidget(id);
    widget?.setProperties(properties);
  }
}
