import { AuthData, DeployStatus, EsaWebIpcApiClient, EventHandler, Panel, Project } from '@api';
import { EsaWebRpcClient } from 'src/fast-api-client/impl/api_client';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useLinkStore } from './linkStore';
import { usePanelStore } from './panelStore';
import { useProjectStore } from './projectStore';

export const ipcClient: EsaWebIpcApiClient = new EsaWebIpcApiClient(`wss://fast.esa-automation.com/ws`, {}); // mc*** da rimuovere. lasciare solo webClient
export const webClient: EsaWebRpcClient = new EsaWebRpcClient(`wss://fast.esa-automation.com/ws`);

export interface IpcState {
  authData: AuthData;
  setAuthData: (authData: AuthData) => void;
  eventHandler: EventHandler;
  registerEvents: () => void;
}

export const useIpcStore = create(
  immer<IpcState>((set, get) => ({
    project: null,
    authData: null,
    session_token: '',
    accountProjectsTree: [],
    eventHandler: {
      projectStructureChanged: async (e: { id: string; structure: Project.ProjectStructure }) => {
        useProjectStore.getState().setProjectStructure(e.id, e.structure);
        useLinkStore.getState().updateResourceTree(e.structure);
        return Promise.resolve();
      },
      projectPanelsChanged: (e: Panel[]) => {
        usePanelStore.getState().updatePanelList(e);
        return Promise.resolve();
      },
      deployStatusChanged: (e: { projectId: string; panelId: string; status: DeployStatus }) => {
        usePanelStore.getState().updatePanelDeployStatus(e.projectId, e.panelId, e.status);
      },
    },
    setAuthData: (authData: AuthData) => {
      if (authData) {
        set({ authData });

        // save session_token
        const metadata = authData.user.metadata;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sessionToken = metadata && 'session_token' in metadata ? (metadata as any).session_token : '';
        webClient.setSessionToken(sessionToken);
      }
    },

    registerEvents: () => {
      ipcClient.setEventHandler(get().eventHandler); // mc*** da rimuovere. lasciare solo webClient
      //webClient.addHandler('deployStatusCanged', get().eventHandler.deployStatusChanged());
    },
  }))
);
