/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentEditorComponent, DocumentEditorFactory } from '@services/documentEditorFactory';
import { Project } from '@api';
import { create } from 'zustand';
import { deepCopy } from '@services/utils';
import { immer } from 'zustand/middleware/immer';
import { ipcClient, webClient } from './ipcStore';
import { useProjectStore } from '@stores/projectStore';
import { useToolbarStore } from '@stores/toolbarStore';

export interface DocumentInfo {
  document: Project.OpenedDocument;
  builder: DocumentEditorComponent;
}

export interface DocumentsState {
  documentsInfo: { [key: string]: DocumentInfo };
  loading: { [key: string]: boolean };
  _interruptedLoading: { [key: string]: boolean };
  _serverManager: { [key: string]: ServerManager };
  saving: { [key: string]: boolean };
  cachedDocumentsId: string[];
  openedDocuments: string[];
  selectedDoc: string | null;
  selectingDoc: string | null;
  oldOpenedDoc: DocumentInfo | null;
  cacheLimit: number;
  copy: { [key: string]: DocumentInfo };
  openDocument: (id: string) => Promise<void>;
  closeOpenedDocument: (documentId: string) => Promise<void>;
  saveOpenedDocument: (documentId: string, save: boolean) => Promise<void>;
  stopLoading: (documentId: string) => void;
  saveStatus: (documentId: string, status: any) => Promise<void>;
  saveDocumentData: (documentId: string, data: any) => Promise<void>;
}

class AsyncStatus {
  waiting?: Promise<void>;
  async awaitDone(): Promise<void> {
    if (this.waiting) {
      await this.waiting;
    } else return Promise.resolve();
  }

  async run(promise: Promise<void>) {
    this.waiting = promise;
    await this.waiting;
    this.waiting = null;
  }
}

class DebouncedAsyncStatus extends AsyncStatus {
  doing?: Promise<void>;
  timeout?: ReturnType<typeof setTimeout>;
  timeoutDebounce = 200;

  constructor(private documentId: string, private set: typeof useDocumentStore.setState) {
    super();
  }

  async debouncedRun(runFunction: () => Promise<void>): Promise<void> {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    await this.run(
      new Promise(async (resolve) => {
        this.timeout = setTimeout(async () => {
          if (this.doing) await this.doing;

          this.set((state) => {
            state.saving[this.documentId] = true;
          });

          this.doing = runFunction();
          await this.doing;
          this.doing = null;

          this.set((state) => {
            state.saving[this.documentId] = false;
          });
          resolve();
        }, this.timeoutDebounce);
      })
    );
  }
}

class ServerManager {
  private saveProjectStatus: DebouncedAsyncStatus;
  private saveStatusStatus: DebouncedAsyncStatus;
  private projectClosing: AsyncStatus;
  private projectSaving: AsyncStatus;
  public openedProjects = useProjectStore.getState().openedProjects;
  private documentIdKey = '';

  constructor(private documentId: Project.ProjectNodeKey, private set: typeof useDocumentStore.setState) {
    this.saveProjectStatus = new DebouncedAsyncStatus(this.documentId.documentId, set);
    this.saveStatusStatus = new DebouncedAsyncStatus(this.documentId.documentId, set);
    this.projectClosing = new AsyncStatus();
    this.projectSaving = new AsyncStatus();
    this.documentIdKey = JSON.stringify(documentId); // utils.generateKey(documentId);
  }

  async saveProjectData(data: any): Promise<void> {
    await this.saveProjectStatus.debouncedRun(async () => {
      this.set((state) => {
        state.documentsInfo[this.documentIdKey].document.doc.data = deepCopy(data);
        state.documentsInfo[this.documentIdKey].document.modified = true;
      });
      try {
        await webClient.updateOpenedDocument(this.documentId, deepCopy(data));
      } catch (error) {
        console.log('updateOpenedDocument', error);
        alert('updateOpenedDocument error: ' + error);
      }
    });
  }

  async saveProject(): Promise<void> {
    this.set((state) => {
      if (state.documentsInfo[this.documentIdKey]) {
        state.documentsInfo[this.documentIdKey].document.modified = false;
      }
    });
    await this.pendingOperations();
    await this.projectSaving.run(ipcClient.saveOpenedDocument(this.documentId));
  }

  async closeProject(): Promise<void> {
    await this.pendingOperations();
    await this.projectClosing.run(ipcClient.closeOpenedDocument(this.documentId));
  }

  async saveStatus(data: any): Promise<void> {
    await this.saveStatusStatus.debouncedRun(async () => {
      this.set((state) => {
        state.documentsInfo[this.documentIdKey].document.editorStatus = data;
      });
      await ipcClient.updateEditorStatus(this.documentId, deepCopy(data));
      return Promise.resolve();
    });
  }

  async pendingOperations(): Promise<void> {
    await this.saveProjectStatus.awaitDone();
    await this.saveStatusStatus.awaitDone();
    await this.projectClosing.awaitDone();
    await this.projectSaving.awaitDone();
  }

  async loadProject(): Promise<Project.OpenedDocument> {
    // Attende il completamento di un'eventuale operazione di salvataggio in corso
    if (this.saveProjectStatus.waiting) {
      await this.saveProjectStatus.waiting;
    }

    await this.pendingOperations();

    try {
      return webClient.openDocument(this.documentId);
    } catch (error) {
      console.log('openDocument', error);
      alert('openDocument error: ' + error);
    }
  }
}

const useDocumentStore = create(
  immer<DocumentsState>((set, get) => ({
    documentsInfo: {},
    loading: {},
    saving: {},
    _interruptedLoading: {},
    _serverManager: {},
    cachedDocumentsId: [],
    openedDocuments: [],
    selectedDoc: null,
    selectingDoc: null,
    cacheLimit: 1,
    oldOpenedDoc: null,
    copy: {},

    stopLoading: (id: string) => {
      set((state) => {
        state._interruptedLoading[id] = true;
      });
    },

    saveStatus: async (id: string, status: any) => {
      const { _serverManager, documentsInfo } = get();
      if (_serverManager[id] && documentsInfo[id]) _serverManager[id].saveStatus(status);
    },

    saveDocumentData: async (id: string, data: any) => {
      const { _serverManager, documentsInfo } = get();
      if (_serverManager[id] && documentsInfo[id]) _serverManager[id].saveProjectData(data);
    },

    openDocument: async (id: string) => {
      const { cachedDocumentsId, loading } = get();

      if (loading[id]) {
        set((state) => {
          state.selectingDoc = id;
          state._interruptedLoading[id] = false;
        });
      } else if (!cachedDocumentsId.includes(id)) {
        try {
          set((state) => {
            state.loading[id] = true;
            state._interruptedLoading[id] = false;
            state.selectingDoc = id;
            if (!state._serverManager[id]) state._serverManager[id] = new ServerManager(JSON.parse(id), set);
          });

          const { _serverManager } = get();
          const serverDocument = await _serverManager[id].loadProject();

          set((state) => {
            if (state._interruptedLoading[id]) {
              state._interruptedLoading[id] = false;
              state.loading[id] = false;
            } else {
              const updatedCache = [...state.cachedDocumentsId, id];

              if (updatedCache.length > state.cacheLimit) {
                const oldestId = updatedCache.shift();
                if (oldestId) {
                  state.documentsInfo[oldestId].document.doc.data = null;
                }
              }

              state.documentsInfo[id] = {
                document: serverDocument,
                builder: DocumentEditorFactory.getDocumentBuilder(serverDocument.doc.type),
              };
              state.copy[id] = deepCopy(state.documentsInfo[id]);

              state.loading[id] = false;
              state.cachedDocumentsId = updatedCache;
              state.selectedDoc = id;
            }
          });
        } catch (error) {
          console.error('Failed to fetch document data:', error);
          set((state) => {
            state.loading[id] = false;
          });
        }
      } else {
        set((state) => {
          state.selectedDoc = state.selectingDoc = id;
          state.cachedDocumentsId = state.cachedDocumentsId.filter((element: string) => element !== id);
          state.cachedDocumentsId.push(id);
          state.loading[id] = false;
        });
      }

      set((state) => {
        if (state.openedDocuments.findIndex((n) => n === id) === -1) {
          state.openedDocuments.push(id);
        }
        state.oldOpenedDoc = null;
      });

      set((state) => {
        useToolbarStore.getState().setDocumentToolbar(state.documentsInfo[id].document.doc.type);
      });
    },

    saveOpenedDocument: async (id: string, save: boolean) => {
      const { _serverManager, documentsInfo } = get();
      if (save && _serverManager[id] && documentsInfo[id]?.document.modified) {
        await _serverManager[id].saveProject();
      } else if (!save) {
        set((state) => {
          state.documentsInfo[id].document = deepCopy(state.copy[id].document);
          state.documentsInfo[id].document.modified = false;
          ipcClient.resetDocumentModifiedFlag(id, state.documentsInfo[id].document);
          _serverManager[id].saveProject();
        });
      }
    },

    closeOpenedDocument: async (id: string) => {
      if (get().openedDocuments.length > 0) {
        let openedDocumentIdx = get().openedDocuments.findIndex((odId: string) => odId === id);

        set((state) => {
          if (openedDocumentIdx >= 0) {
            state.openedDocuments.splice(openedDocumentIdx, 1);
          }
        });

        const { openedDocuments, openDocument, selectedDoc, selectingDoc } = get();

        if (openedDocumentIdx >= openedDocuments.length) {
          openedDocumentIdx = openedDocuments.length - 1;
        }

        if (selectedDoc === id) {
          set((state) => {
            state.selectedDoc = null;
          });
        }

        set((state) => {
          state.cachedDocumentsId = state.cachedDocumentsId.filter((cdId: string) => cdId !== id);
          delete state.copy[id];
          delete state.documentsInfo[id];
          delete state.loading[id];
          delete state.saving[id];
          delete state._interruptedLoading[id];
        });

        if (selectingDoc === id) {
          if (openedDocuments.length) {
            const key = openedDocuments[openedDocumentIdx];
            openDocument(key);
          } else {
            set((state) => {
              state.selectingDoc = null;
            });
          }
        }

        const currServerManager = await get()._serverManager[id];
        if (currServerManager) {
          currServerManager.closeProject();

          set((state) => {
            delete state._serverManager[id];
          });

          set((state) => {
            // remove toolbar for the last document
            if (openedDocuments.length === 0) useToolbarStore.getState().setDocumentToolbar('close');
          });
        }
      }
    },
  }))
);

export default useDocumentStore;
