import '/node_modules/primeflex/primeflex.css';
import { Button } from 'primereact/button';
import { DeployComponent } from './deploy/Deploy';
import { InputText } from 'primereact/inputtext';
import { MegaMenu } from 'primereact/megamenu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProjectsTree } from './common/ProjetcsTree';
import { Toast } from 'primereact/toast';
import { useIpcStore, webClient } from '@stores/ipcStore';
import { useLayoutStore } from '@stores/layoutStore';
import { useProjectStore } from '@stores/projectStore';
import { useRef } from 'react';

export const Toolbar = () => {
  const authData = useIpcStore((state) => state.authData);
  const openedProjects = useProjectStore((state) => state.openedProjects);
  const { loadAccountProjectsList, setOpenedProjects } = useProjectStore();
  const activateTab = useLayoutStore((state) => state.activateTab);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toast = useRef<any>(null);
  const projectOp = useRef<OverlayPanel>(null);
  const deployOp = useRef<OverlayPanel>(null);
  // const connected = false;

  // const cli_authenticate = async () => {
  //   try {
  //     const risp = await ipcClient.authenticate('test', 'user', 'password');
  //     console.log(`Authenticated to server `, risp);
  //     toast.current.show({ severity: 'success', summary: 'Success', detail: 'Login Eseguito', life: 3000 });
  //   } catch (ex: any) {
  //     toast.current.show({ severity: 'error', summary: 'error', detail: ex.message });
  //     console.log('due', ex);
  //   }
  // };

  /* const cli_openproject = async (projectId: string) => {
    try {
      const risp = await ipcClient.openProject('d295fb8a-d622-11ee-99a5-0242ac140003');
      console.log(`openProject to server `, risp);
      setOpenedProjects(risp);
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Progetto Aperto', life: 3000 });
      // await client.closeProject()
    } catch (ex: any) {
      toast.current.show({ severity: 'error', summary: 'error', detail: ex.message });
      console.log('due', ex);
    }
  }; */

  /* const cli_closeproject = async (projectId: string) => {
    try {
      if (projects[projectId] !== undefined) {
        await ipcClient.closeProject(projectId);
        setOpenedProjects(null);
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Progetto Chiuso', life: 3000 });
      }
    } catch (ex: any) {
      toast.current.show({ severity: 'error', summary: 'error', detail: ex.message });
      console.log('due', ex);
    }
  }; */

  /* const cli_connect = async () => {
    if (connected) return;
    try {
      connected = true;
      ipcClient.timeout = 10000;
      const risp = await ipcClient.connectWithServer('nh3AuWBjfHWPKEzSYPg5y');
      console.log(`Connected to server `, risp);
      await cli_authenticate();
    } catch (ex: any) {
      toast.current.show({ severity: 'error', summary: 'error', detail: ex.message });
      console.log('uno', ex);
    }
  }; */

  const items = [
    /*
        {
            label: 'Login Utente',
            key:'1',
            icon: 'pi pi-file',
            command: () => {
                cli_connect();
            }
        },
				*/
    {
      label: 'Apri Progetto',
      key: '2',
      icon: 'pi pi-search',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      command: (e: any) => {
        loadAccountProjectsList();
        projectOp.current.toggle(e.originalEvent);
      },
    },
    /*
        {
            label: 'Chiudi Progetto',
            key:'3',
            icon: 'pi pi-cloud',
            command: () => {
                cli_closeproject()
            }
        },*/
    {
      label: 'Deploy',
      key: '4',
      icon: 'pi pi-check',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      command: (e: any) => {
        if (openedProjects !== null) {
          deployOp.current.toggle(e.originalEvent);
        }
      },
    } /*
        {
            label: '',
            key:'5',
            icon: 'pi pi-search',
            command: () => {
                toast.current.show({ severity: 'info', summary: 'Shared', detail: 'Exported to cloud', life: 3000 });
            }
        }
				*/,
  ];

  const start = (
    <img
      alt="logo"
      style={{ marginRight: '20px', marginLeft: '20px', marginTop: '4px' }}
      src="https://www.esa-automation.com/wp-content/uploads/logo.png?a=19"
      height="32"
      className="mr-2"
    ></img>
  );
  const end = (
    <div style={{ marginRight: '20px', marginTop: '0px' }} className="flex gap-2 align-items-center">
      <InputText placeholder="Search" type="text" className="w-8rem sm:w-auto p-inputtext-sm" />
      {authData && <div style={{ marginLeft: '3rem' }}>{authData.user.username}</div>}
      <Button style={{ color: 'white' }} icon="pi pi-user" rounded text raised severity="info" aria-label="User" />
    </div>
  );

  return (
    <div className="card toolbar-container">
      <MegaMenu model={items} orientation="horizontal" start={start} end={end} />
      <Toast ref={toast} />
      <OverlayPanel className="open-project-overlay" ref={projectOp}>
        <ProjectsTree
          onProjectSelected={async (projectId) => {
            let project = null;
            try {
              project = await webClient.openProject(projectId);
            } catch (error) {
              console.log('openProject', error);
              alert('openProject error: ' + error);
            }
            if (project) setOpenedProjects(project);
            projectOp.current.hide();
            activateTab('ProjectManager');
          }}
        />
      </OverlayPanel>
      <OverlayPanel ref={deployOp}>
        <DeployComponent />
      </OverlayPanel>
    </div>
  );
};
