/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ActionProps {
  icon: string;
  code: string;
  disabled?: boolean;
}

export interface BasicProps {
  id: any;
  title: string;
  icon: any;
  editable?: boolean;
  actions?: ActionProps[];
  hint?: string;
}

export interface CommonProps extends BasicProps {
  found: boolean;
  hidden: boolean;
  autoedit?: boolean;
  sendMessageToTreeview?: any;
  showModal?: any;
}

export interface LeafProps extends CommonProps {
  selected: boolean;
  childFound: boolean;
  data?: any;
}

export const emptyLeafProps: LeafProps = {
  hidden: true,
  autoedit: false,
  selected: false,
  editable: true,
  found: false,
  id: null,
  icon: null,
  title: '',
  actions: [],
  childFound: false,
};

export interface NodeStatus {
  open: boolean;
  forceOpen?: boolean;
  parentFound: boolean;
}

export interface NodeProps extends NodeStatus, CommonProps {
  children?: Array<LeafProps | NodeProps>;
  setOpenNodes?: any;
  selected?: boolean;
}

export const emptyNodeProps: NodeProps = {
  open: false,
  autoedit: false,
  hidden: true,
  editable: true,
  found: false,
  children: [],
  id: null,
  icon: null,
  title: '',
  actions: [],
  parentFound: false,
  selected: false,
};

export function isLeafProps(obj: any) {
  if (obj.hasOwnProperty('children')) {
    if (Array.isArray(obj.children)) {
      return false;
    }
  } else return true;

  return true;
}

export interface TreeviewProps {
  json: string;
  selectedItems: string[];
  notifyAction: (actionName: string, jsonString: string, event: MouseEvent) => void;
  utility?: { spinner: boolean };
}
