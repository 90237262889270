/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IpcState, ipcClient, useIpcStore, webClient } from '@stores/ipcStore';
import { LeafProps, NodeProps, Treeview, TreeviewProps } from './treeview';
import { ProjectTree } from '@api';
import { Toast } from 'primereact/toast';
import { useProjectStore } from '@stores/projectStore';
import React, { useEffect, useRef, useState } from 'react';

interface ProjectsTreeProps {
  onProjectSelected: (projectId: string) => void;
}

export const ProjectsTree: React.FC<ProjectsTreeProps> = ({ onProjectSelected }) => {
  const [objID, setObjID] = useState<string>();
  const [treeJSON, setTreeJSON] = useState<NodeProps[]>([]);

  const [treeviewJson, setState] = useState(JSON.stringify(treeJSON));

  const [selectedItems, setSelectedItems] = useState(Array<string>());

  const [showSpinner, setshowSpinner] = useState(false);

  const accountProjectsTree = useProjectStore((state) => state.accountProjectsTree);
  const authData = useIpcStore((state: IpcState) => state.authData);
  const loadAccountProjectsList = useProjectStore((state) => state.loadAccountProjectsList);
  const toast = useRef<any>();

  /**
   * Ricerca progetti da eliminare dal DOM
   * @param oldTree vecchio albero prima della cancellazione
   * @param newTree nuovo albero dopo la cancellazione
   */
  async function findRemovedProjects(oldTree: any[], newTree: any[]) {
    //estrazione progetti da folder
    function getAllProjects(tree: any[]): any[] {
      let projects: ProjectTree.Project[] = [];

      for (const node of tree) {
        if (node.project_id) {
          projects.push(node);
        } else if (node.children) {
          projects = projects.concat(getAllProjects(node.children));
        }
      }
      return projects;
    }

    const oldProjects = getAllProjects(oldTree);
    const newProjects = getAllProjects(newTree);

    //controllo esistenza progetto
    function projectExistsInNewTree(project: ProjectTree.Project): boolean {
      return newProjects.some((newProject) => newProject.project_id === project.project_id);
    }

    //controllo progetti da elininare
    for (const oldProject of oldProjects) {
      if (!projectExistsInNewTree(oldProject)) {
        await ipcClient.removeProject(oldProject.project_id); //cancellazione dal ProjectTree
        useProjectStore.getState().deleteProject(oldProject.project_id); //cancellazione dal DOM
      }
    }
  }

  const buildTreeviewObj = (nodes: ProjectTree.RootFolder): Array<LeafProps | NodeProps> => {
    return nodes.map((node) => {
      if ('description' in node) {
        // Node is a Project
        return {
          selected: false,
          childFound: false,
          title: node.description,
          id: node.project_id,
          open: true,
          autoedit: node.project_id === objID,
          parentFound: false,
          found: false,
          hidden: false,
          editable: false,
          icon: undefined,
          actions: [
            { icon: 'pi pi-pencil', code: 'edit' },
            { icon: 'pi pi-times', code: 'deleteProject' },
          ],
          // Add other necessary properties
        };
      } else {
        // Node is a Folder
        return {
          selected: false,
          childFound: false,
          title: node.name,
          id: node.folder_id,
          open: true,
          autoedit: node.folder_id === objID,
          parentFound: false,
          found: false,
          hidden: false,
          editable: false,
          icon: undefined,
          actions: [
            { icon: 'pi pi-plus', code: 'newProject' },
            { icon: 'pi pi-folder', code: 'newFolder' },
            { icon: 'pi pi-pencil', code: 'edit' },
            { icon: 'pi pi-times', code: 'deleteFolder' },
          ],
          children: buildTreeviewObj(node.children),
        };
      }
    });
  };

  useEffect(() => {
    let treeviewObj: NodeProps[] = [];

    if (authData) {
      treeviewObj = [
        {
          title: authData.domain.name,
          id: 'root',
          open: true,
          parentFound: false,
          found: false,
          hidden: false,
          editable: false,
          icon: undefined,
          actions: [
            { icon: 'pi pi-plus', code: 'newProject' },
            { icon: 'pi pi-folder', code: 'newFolder' },
          ],

          children: buildTreeviewObj(accountProjectsTree),
        },
      ];
    }

    setState(JSON.stringify(treeviewObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountProjectsTree, objID]);

  async function notifyAction(action: string, data: string) {
    const dt = data ? JSON.parse(data) : {};
    switch (action) {
      case 'select':
        if (dt.hasOwnProperty('id')) {
          setSelectedItems([dt.id]);
        }
        break;
      case 'leaf-double-click':
        if (dt.hasOwnProperty('id')) {
          onProjectSelected(dt.id);
        }
        break;
      case 'newFolder':
      case 'newProject':
        try {
          if (dt.hasOwnProperty('id')) {
            if (action === 'newFolder') {
              try {
                const risp: any = await webClient.createProjectFolder('Folder', dt.id);
                setObjID(risp);
              } catch (error) {
                console.log('createProjectFolder', error);
                alert('createProjectFolder error: ' + error);
              }
            } else {
              try {
                const risp: any = await webClient.createProject('Project', dt.id);
                setObjID(risp);
              } catch (error) {
                console.log('createProject', error);
                alert('createProject error: ' + error);
              }
            }
            await loadAccountProjectsList();
          }
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Creato con successo',
          });
        } catch (ex: any) {
          toast.current.show({
            severity: 'error',
            summary: 'error',
            detail: ex.message,
          });
          console.log('create', ex);
          alert('create error' + ex);
        }
        break;
      case 'deleteFolder':
      case 'deleteProject':
        try {
          const oldTree = await webClient.getProjectTree();

          if (action === 'deleteFolder') {
            await ipcClient.removeProjectFolder(dt.id);
          } else if (action === 'deleteProject') {
            await ipcClient.removeProject(dt.id);
            useProjectStore.getState().deleteProject(dt.id);
          }

          await loadAccountProjectsList();
          const updatedTree = await webClient.getProjectTree();

          if (action === 'deleteFolder') {
            findRemovedProjects(oldTree, updatedTree);
          }

          useProjectStore.getState().setProjectTree(updatedTree);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Rimosso con successo',
          });
        } catch (ex: any) {
          toast.current.show({
            severity: 'error',
            summary: 'error',
            detail: ex.code + ': ' + ex.message,
          });
          console.log('getProjectTree', ex);
          alert('getProjectTree error: ' + ex);
        }
        break;
      case 'edit':
        if (dt.hasOwnProperty('id') && dt.hasOwnProperty('newTitle')) {
          if (dt.type === 'leaf') {
            try {
              await webClient.renameProject(dt.id, dt.newTitle);
            } catch (error) {
              console.log('renameProject', error);
              alert('renameProject error: ' + error);
            }

            await loadAccountProjectsList();

            const isProjectOpened = useProjectStore.getState().openedProjects[dt.id];
            if (isProjectOpened) {
              useProjectStore.getState().updateProject(dt.id, { id: dt.id, description: dt.newTitle });
              try {
                const updatedProject = await ipcClient.openProject(dt.id);
                useProjectStore.getState().setOpenedProjects(updatedProject);
              } catch (error) {
                console.log('openProject', error);
                alert('openProject error: ' + error);
              }
            }
          } else {
            // project folder
            try {
              await webClient.renameProjectFolder(dt.id, dt.newTitle);
            } catch (error) {
              console.log('renameProjectFolder', error);
              alert('renameProjectFolder error: ' + error);
            }
            await loadAccountProjectsList();
          }

          try {
            const updatedTree = await webClient.getProjectTree();
            useProjectStore.getState().setProjectTree(updatedTree);
          } catch (ex) {
            toast.current.show({
              severity: 'error',
              summary: 'error',
              detail: ex.code + ': ' + ex.message,
            });
            console.log('getProjectTree', ex);
            alert('getProjectTree error: ' + ex);
          }
        }
        break;
      case 'drag-&-drop':
        switch (dt.startType) {
          // project
          case 'leaf':
            await ipcClient.moveProject(dt.startId, dt.endId, 0);
            break;
          // project folder
          case 'node':
            await ipcClient.moveProjectFolder(dt.startId, dt.endId, 0);
            break;
        }
        await loadAccountProjectsList();
        break;
    }
  }

  const pr: TreeviewProps = {
    json: treeviewJson,
    selectedItems: selectedItems,
    notifyAction: notifyAction,
    utility: { spinner: showSpinner },
  };

  return (
    <>
      <div>
        <Treeview {...pr} />
      </div>
      <Toast ref={toast} />
    </>
  );
};
