import 'tabulator-tables/dist/css/tabulator_simple.min.css';
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import { PreviewMediaEventManager, PropertiesManager } from '../properties-manager';
import { WidgetContainer } from '../widget-container';
import { WidgetProperties } from '../widget';

export class Table extends WidgetContainer {
  private data: any[] = [];
  private readonly mediaHandler: PreviewMediaEventManager;
  private tabulatorInstance: Tabulator | null = null;

  constructor() {
    super();
    this.domElement.classList.add('tabulator-table-container');
    this.mediaHandler = new PreviewMediaEventManager();
    PropertiesManager.setMediaEventManager(this.mediaHandler);
  }

  attach(): void {
    this.initializeTable();
    setTimeout(() => {
      // this.updateColumns();
    }, 5000);
  }

  setProperties(properties: WidgetProperties): void {
    this.setWidgetContainerProperties(properties);
    for (const key in properties) {
      switch (key) {
        default:
          if (key.includes('Font')) {
            this.setFontProperties(properties, this.domElement, 'Table');
          } else {
            this.setDefaultProperties(properties);
          }
      }
    }
  }

  setWidgetContainerProperties(properties: WidgetProperties): void {
    super.setProperties(properties);
    this.data = [];
    if ('data' in properties) {
      this.data = properties['data']; // Assegna i dati passati tramite proprietà
      this.renderTable();
    }
  }

  private initializeTable(): void {
    const tabledata = [
      { id: 1, name: 'aa', field: 'field', dob: '31/08/1997', age: 22, height: 166 },
      { id: 2, name: 'bb', field: 'field', dob: '06/06/1996', age: 83, height: 172 },
      { id: 3, name: 'cc', field: 'field', dob: '07/07/1995', age: 72, height: 176 },
      { id: 4, name: 'dd', field: 'field', dob: '07/12/1998', age: 26, height: 180 },
      { id: 5, name: 'ee', field: 'field', dob: '27/07/1999', age: 57, height: 160 },
      { id: 6, name: 'ff', field: 'field', dob: '17/10/1995', age: 11, height: 167 },
      { id: 7, name: 'gg', field: 'field', dob: '14/04/1998', age: 95, height: 183 },
    ];

    if (this.domElement) {
      if (!this.tabulatorInstance) {
        this.tabulatorInstance = new Tabulator(this.domElement, {
          data: tabledata,
          resizableColumnFit: true,
          layout: 'fitColumns',
          resizableRows: true,
          resizableRowGuide: true,
          resizableColumnGuide: true,
          columnDefaults: {
            resizable: true,
          },
          columns: [
            { title: 'Name', field: 'name', sorter: 'string', width: 200 },
            { title: 'Age', field: 'age', sorter: 'number', hozAlign: 'right', formatter: 'progress' },
          ],
        });
      }
    } else {
      console.error("Elemento DOM non trovato per l'inizializzazione di Tabulator.");
    }
  }

  private renderTable(): void {
    if (this.tabulatorInstance) {
      this.tabulatorInstance.setData(this.data);
      this.setColumns(); // Aggiungi setColumns per definire le colonne inizialmente
    } else {
      this.initializeTable();
    }
  }

  private setColumns(): void {
    const newColumns: ColumnDefinition[] = [
      { title: 'Name', field: 'name', sorter: 'string', width: 200 },
      { title: 'Age', field: 'age', sorter: 'number', hozAlign: 'right', formatter: 'progress' },
    ];

    if (this.tabulatorInstance) {
      this.tabulatorInstance.setColumns(newColumns);
    }
  }
  // aggiorna le colonne
  // private updateColumns(): void {
  //   const updatedColumns: ColumnDefinition[] = [
  //     { title: 'Id', field: 'id', sorter: 'number' },
  //     { title: 'Full Name', field: 'name', sorter: 'string' },
  //     { title: 'Years', field: 'age', sorter: 'number', formatter: 'progress' },
  //     { title: 'Height (cm)', field: 'height', hozAlign: 'center' },
  //     { title: 'field', field: 'field', sorter: 'string' },
  //     { title: 'Birth Date', field: 'dob', sorter: 'date', hozAlign: 'center' },
  //   ];

  //   if (this.tabulatorInstance) {
  //     this.tabulatorInstance.setColumns(updatedColumns);
  //   }
  // }
}
