/* eslint-disable @typescript-eslint/no-explicit-any */
import './MultiPropertyArray.css';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { PropertiesConfig } from '@config/widget/interface';
import { PropertyEditorComponentProps } from '../utils/PropertyEditorFactory';
import { PropertyEditorOptions } from '../main/PropertyEditorComponent';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useWidgetPropsStore } from '@stores/widgetPropsStore';
import PropertyList, { insertItem } from '../main/PropertyListEditorComponent';

export interface MultiPropertyArrayItemProps extends PropertyEditorComponentProps {
  subProperties: PropertiesConfig;
}

interface elementListProp {
  id: string;
  position: number;
  [key: string]: any;
}

let sortedElementList = [];

const MultiPropertyArrayEditor = ({ data, subProperties, onDataChange }: MultiPropertyArrayItemProps) => {
  const [elementList, setElementList] = useState<elementListProp[]>([]);
  const [selectedElement, setSelectedElement] = useState<string | ''>('');
  const [newId, setNewId] = useState<number>(1);
  const [propertiesList, setPropertiesList] = useState<PropertyEditorOptions[]>([]);
  const editingProperties = useWidgetPropsStore((state) => state.editingProperties);
  const widgetId = editingProperties.data?.['id'];
  const op = useRef<OverlayPanel>(null);
  const del = useRef<OverlayPanel>(null);

  const getNewElementId = (): number => {
    setNewId((prevId) => prevId + 1);
    return newId;
  };

  const handleSelectElement = (element: elementListProp) => {
    if (element) {
      setSelectedElement(element.id);
      const newList: PropertyEditorOptions[] = [];

      Object.entries(subProperties.properties).map(([key, editorConfig]) => {
        const currValue = { ...editorConfig };
        const keyArray = key.split('.');
        if (keyArray.length > 2) {
          if (data && data[element.id]) {
            if (data[element.id][keyArray[2]] !== undefined) {
              currValue.defaultData = data[element.id][keyArray[2]];
            }
          }
        }

        const newKey = key.replace('$Key', element.id);
        insertItem(newList, widgetId, newKey, currValue);
      });
      setPropertiesList(newList);
    }
  };

  useEffect(() => {
    const newList: elementListProp[] = [];
    sortedElementList = [];
    let maxId = 0;
    if (data) {
      Object.keys(data).forEach((key) => {
        const element = data[key];
        newList.push(element);
        const keyArray = key.split('_');
        if (keyArray.length > 1) {
          const currentId = parseInt(keyArray[1]);
          if (!isNaN(currentId) && currentId > maxId) {
            maxId = currentId;
          }
        }
      });
    }
    setNewId(maxId + 1);
    setElementList(newList);
    sortedElementList = [...newList].sort((a, b) => a.position - b.position);
    if (selectedElement !== '') {
      const currSelectedElement = newList.find((elem) => {
        return elem.id === selectedElement;
      });
      if (currSelectedElement) handleSelectElement(currSelectedElement);
    }
  }, [data]);

  const onChangeDataToReturn = (newlist: elementListProp[]) => {
    const newPropertyToReturn: { [key: string]: any } = {};
    newlist.forEach((element) => {
      newPropertyToReturn[element.id] = element;
    });
    onDataChange(newPropertyToReturn);
  };

  const handleOpenEdit = (e: SyntheticEvent) => {
    setPropertiesList([]);

    op.current?.toggle(e);
  };

  const addItem = () => {
    const newItemId = getNewElementId();
    const newItem: elementListProp = {
      id: 'item_' + newItemId.toString(),
      position: elementList.length,
    };
    const newEditorOptions = [...elementList, newItem];
    setElementList(newEditorOptions);
    onChangeDataToReturn(newEditorOptions);
    handleSelectElement(newItem);
  };

  const handleDelete = () => {
    const newList = elementList.filter((elem) => elem.id !== selectedElement);
    const updatedList = newList.map((item, index) => ({
      ...item,
      position: index,
    }));
    setSelectedElement(null);
    setElementList(updatedList);
    onChangeDataToReturn(updatedList);
  };

  const moveUp = () => {
    const index = getSelectedIndex();
    if (index > 0) {
      const newList = elementList.map((item) => ({ ...item }));
      const temp = newList[index - 1];
      newList[index - 1] = { ...newList[index], position: index - 1 };
      newList[index] = { ...temp, position: index };
      setElementList(newList);
      onChangeDataToReturn(newList);
      setSelectedElement(newList[index - 1].id);
    }
  };

  const moveDown = () => {
    const index = getSelectedIndex();
    if (index < elementList.length - 1) {
      const newList = elementList.map((item) => ({ ...item }));
      const temp = newList[index + 1];
      newList[index + 1] = { ...newList[index], position: index + 1 };
      newList[index] = { ...temp, position: index };
      setElementList(newList);
      onChangeDataToReturn(newList);
      setSelectedElement(newList[index + 1].id);
    }
  };

  const getSelectedIndex = () => {
    return selectedElement ? elementList.findIndex((item) => item.id === selectedElement) : -1;
  };

  const getSelectedElement = () => {
    return elementList.find((elem) => elem.id === selectedElement);
  };

  const ElementTemplate = (element: elementListProp) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className="m-2 p-1">{element.id} </div>
        <div className="flex justify-end align-items-center w-3">
          <Button
            onClick={(ev) => del.current?.show(ev, ev.target)}
            icon="pi pi-trash"
            size="small"
            className="p-button-danger text-white"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Button
        style={{ width: '2.3rem', height: '2.3rem', fontSize: '1.3rem' }}
        icon="fa-solid fa-pencil"
        aria-label="Items list"
        outlined
        onClick={(e) => handleOpenEdit(e)}
      />
      <OverlayPanel ref={op} closeOnEscape dismissable={true}>
        <div className="flex flex-row" style={{ width: '550px', height: '350px' }}>
          <div className="flex flex-column gap-2 mr-4">
            <Button icon="pi pi-plus" onClick={() => addItem()} style={{ height: '3rem', marginBottom: '5px' }} />
            <Button icon="pi pi-arrow-up" onClick={() => moveUp()} style={{ height: '3rem' }} />
            <Button icon="pi pi-arrow-down" onClick={() => moveDown()} style={{ height: '3rem' }} />
          </div>
          <ListBox
            value={getSelectedElement()}
            onChange={(e) => handleSelectElement(e.value)}
            options={sortedElementList}
            optionLabel="id"
            itemTemplate={ElementTemplate}
            className="w-full md:w-14rem"
            style={{ height: '350px', minWidth: '150px', overflowY: 'auto' }}
          />
          <Divider layout="vertical"></Divider>
          {selectedElement && (
            <Fieldset style={{ width: '300px' }}>
              <PropertyList list={propertiesList} addResetChildFunction={null} editingProperties={editingProperties} />
            </Fieldset>
          )}
        </div>
      </OverlayPanel>
      <OverlayPanel ref={del}>
        <div className="flex flex-column gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-2xl pi pi-exclamation-circle" />
            <span className="font-bold">Sei sicuro di voler procedere?</span>
          </div>
          <div className="flex flex-row-reverse gap-2">
            <Button onClick={() => del.current.hide()} label="No" />
            <Button
              label="Si"
              onClick={() => {
                del.current.hide();
                handleDelete();
              }}
            />
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

export default MultiPropertyArrayEditor;
