/* eslint-disable @typescript-eslint/no-explicit-any */
import { Project } from '@api';
import { TabName, TextPosition } from './enum';
import { TableFieldConfig } from './interface';
import { merge } from './widgetData';
import InputTextEditor from '@components/property-edit/component/InputTextComponent';
import MultiPropertyArrayEditor from '@components/property-edit/component/MultiPropertyArrayComponent';
import NumberSlider from '@components/property-edit/component/NumberSliderComponent';
import ToggleButtonBar from '@components/property-edit/component/ToggleButtonComponent';

export const tableField = (config: TableFieldConfig) => ({
  properties: {
    Columns: {
      tab: TabName.Content,
      section: 'Column',
      caption: 'Column',
      editor: MultiPropertyArrayEditor,
      pinType: Project.PinType.None,
      captionPosition: TextPosition.Left,
      editorOptions: {
        subProperties: merge({
          properties: {
            'Columns.$Key.Header': {
              defaultData: 'Header',
              caption: 'Header',
              editor: InputTextEditor,
              captionPosition: TextPosition.Left,
              isResponsive: true,
              pinType: Project.PinType.None,
            },
            'Columns.$Key.Width': {
              editorOptions: {
                minValue: 0,
                maxValue: 100,
              },
              defaultData: 50,
              caption: 'Larghezza',
              editor: NumberSlider,
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },
            'Columns.$Key.Visible': {
              tab: TabName.Content,
              section: 'Column',
              caption: 'Visible',
              defaultData: true,
              editor: ToggleButtonBar,
              captionPosition: TextPosition.Left,
              isResponsive: true,
              pinType: Project.PinType.None,
            },
          },
        }),
      },
    },
  },
});
