/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthClient, AuthData } from '../types/auth_client';
import { ConnState, ConnType, DeployStatus, Panel, PanelManager, SyncStats } from '../types/panels';
import { DatabaseAPI, DatabaseInfos, Table } from '../types/database';
import { DeployData, TestingAPI } from '../types/testing';
import { EventDispatcher, EventHandler } from '../types/event_handling';
import { Project } from '../types/project';
import { ProjectEditor } from '../types/project_editor';
import { ProjectManager } from '../types/project_manager';
import { ProjectTree } from '../types/project_tree';
import { Resource, ResourceManager, ResourceType } from '../types/resources';
import { User } from '../types/user';
import { UserManager } from '../types/user_manager';
import { deepCopy } from '@services/utils';
import { documentEditorsConfig } from '@config/documentEditor';
import { useLayersEditorStore } from '@stores/layersStore';

interface Logger {
  [k: string]: any;
}

export class EsaWebIpcApiClient
  // AuthClient,
  implements
    UserManager,
    ProjectTree.Manager,
    ProjectManager,
    ProjectEditor,
    PanelManager,
    ResourceManager,
    TestingAPI,
    EventDispatcher,
    DatabaseAPI
{
  connectWithServer(__arg0: string): Promise<void> {
    return Promise.resolve();
  }

  resetDocumentModifiedFlag(id: string, documentCopy: Project.OpenedDocument): Promise<void> {
    if (this.openedDocuments[id]) {
      this.openedDocuments[id].modified = false;
      this.openedDocuments[id] = deepCopy(documentCopy);
    }
    return Promise.resolve();
  }

  collectNode(node: ProjectTree.Node): ProjectTree.Node {
    if ('project_id' in node) {
      return {
        project_id: node.project_id,
        description: node.description,
      };
    } else if ('children' in node) {
      return {
        folder_id: node.folder_id,
        name: node.name,
        children: node.children.map((child) => this.collectNode(child)),
      };
    }
    return node;
  }

  /* async listProjects(__filter?: string): Promise<Array<ProjectTree.Node>> {
    const ret = this.projectTree
      .map((node: ProjectTree.Node) => {
        return this.collectNode(node);
      })
      .filter((node) => {
        if (__filter) {
          if ('description' in node) {
            return node.description.includes(__filter);
          } else if ('name' in node) {
            return (
              node.name.includes(__filter) ||
              node.children.some((child) => {
                if ('description' in child) {
                  return child.description.includes(__filter);
                } else if ('name' in child) {
                  return child.name.includes(__filter);
                }
                return false;
              })
            );
          }
        }
        return true;
      });

    return Promise.resolve(ret);
  } */

  timeout = 0;

  delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  parseProjectTree = (data: ProjectTree.Project): ProjectTree.RootFolder => {
    const rootFolder: ProjectTree.RootFolder = [];

    const processNode = (value: any): ProjectTree.Node => {
      if (value.project_id) {
        return value as ProjectTree.Project;
      } else {
        return {
          folder_id: value.folder_id,
          name: value.name,
          children: value.children ? value.children.map((child: any) => processNode(child)) : [],
        } as ProjectTree.Folder;
      }
    };

    for (const key in data) {
      rootFolder.push(processNode(data[key]));
    }

    return rootFolder;
  };

  projectList: { [key: string]: Project.Project } = JSON.parse(localStorage.getItem('ProjectList') ?? '{}');
  projectTree: ProjectTree.RootFolder = this.parseProjectTree(JSON.parse(localStorage.getItem('ProjectTree') ?? '{}'));
  fileSystem: Project.BinaryFolder = JSON.parse(
    localStorage.getItem('FileSystem') ??
      '{"name":"root","subFolders":[{"name":"test","subFolders":[],"files":[]}],"files":[]}'
  );

  openedDocuments: { [Key: string]: Project.OpenedDocument } = {};

  openedProjects: { [Key: string]: Project.Project } = {};

  eventHandler: EventHandler | null = null;
  documentUniqueId: number = +(localStorage.getItem('documentUniqueId') ?? '100');
  folderUniqueId: number = +(localStorage.getItem('folderUniqueId') ?? '200');

  syncDB() {
    localStorage.setItem('ProjectTree', JSON.stringify(this.projectTree));
    localStorage.setItem('ProjectList', JSON.stringify(this.projectList));
    localStorage.setItem('FileSystem', JSON.stringify(this.fileSystem));
  }

  findNode(list: Project.ProjectNode[] | undefined, nodeId: string): Project.ProjectNode | null {
    if (list) {
      for (const n of list) {
        if (n.nodeId.documentId === nodeId) {
          return n;
        } else if (this.isFolder(n)) {
          const ret = this.findNode(n.children, nodeId);
          if (ret) return ret;
        }
      }
    }
    return null;
  }

  isDocument(obj: any): obj is Project.Document {
    return obj.type !== undefined;
  }

  isFolder(obj: any): obj is Project.Folder {
    return obj.children !== undefined;
  }

  /* I ProjectData vanno puliti eliminando metadata */
  clearProjectdata(tree: Project.ProjectNode): void {
    if (this.isDocument(tree)) {
      delete tree.data?.metadata;
    } else tree.children.forEach((t) => this.clearProjectdata(t));
  }

  clone<T>(obj: T | undefined): T {
    return JSON.parse(JSON.stringify(obj)) as T;
  }

  cleanProjectTree(project: Project.Project | undefined): Project.Project {
    const ret = this.clone<Project.Project>(project);
    if (ret.structure) {
      Object.keys(ret.structure).forEach((key) => {
        ret.structure[key].forEach((node) => {
          this.clearProjectdata(node);
        });
      });
    }
    return ret;
  }

  findDocumentParentList(projectId: string, parentId: string): Project.ProjectNode[] | undefined {
    let parentList: Project.ProjectNode[] | undefined = [];
    const currProject: Project.Project = this.openedProjects[projectId];
    if (currProject && currProject.structure) {
      if (currProject.structure[parentId]) {
        parentList = currProject.structure[parentId];
      } else {
        let parent: Project.ProjectNode | undefined = undefined;

        for (const key of Object.keys(currProject.structure)) {
          parent = this.findNode(currProject.structure[key], parentId);
          if (parent) break;
        }

        if (parent && this.isFolder(parent)) {
          parentList = parent.children;
        }
      }
    }
    return parentList;
  }

  findProjectNode(projectId: string, id: string): Project.ProjectNode | null {
    const currProject: Project.Project = this.openedProjects[projectId];
    let node: Project.ProjectNode | null = null;

    if (currProject && currProject.structure) {
      for (const key of Object.keys(currProject.structure)) {
        node = this.findNode(currProject.structure[key], id);
        if (node) break;
      }
    }

    return node;
  }

  updateProjectStructure(documentId: string, updatedData: Project.DocumentData, nodes: Project.ProjectNode[]): boolean {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      if ('data' in node && node.nodeId.documentId === documentId) {
        node.data = updatedData;
        return true;
      } else if ('children' in node) {
        const found = this.updateProjectStructure(documentId, updatedData, node.children);
        if (found) {
          return true;
        }
      }
    }
    return false;
  }

  notifyStructure(projectId: string) {
    const currProject: Project.Project = { ...this.openedProjects[projectId] };
    if (currProject) {
      const cleaned = this.cleanProjectTree(currProject).structure;
      if (cleaned)
        this.eventHandler?.projectStructureChanged?.({
          structure: cleaned,
          id: currProject.id,
        });
    }
  }

  allocNewDocumentId(): string {
    const newId = this.documentUniqueId++;
    localStorage.setItem('documentUniqueId', this.documentUniqueId.toString());
    return newId.toString();
  }

  allocNewFolderId(): string {
    const newId = this.folderUniqueId++;
    localStorage.setItem('folderUniqueId', this.folderUniqueId.toString());
    return newId.toString();
  }

  currPanelsList: Panel[] = [
    {
      id: '10:11:12:13:14',
      deviceLabel: 'Main3',
      connUri: '192.168.1.3:8080',
      connState: ConnState.Connected,
      connType: ConnType.Normal,
      projectId: 'ID1',
      projectRole: 'Main',
      serialIsValid: true,
    },
    {
      id: '11:12:13:14:15',
      deviceLabel: 'Main2',
      connUri: '192.168.1.4:3000',
      connState: ConnState.Connecting,
      connType: ConnType.Reverse,
      projectId: 'ID1',
      projectRole: 'Main',
      serialIsValid: true,
    },
    {
      id: '12:13:14:15:16',
      deviceLabel: 'Scan1',
      connUri: '192.168.1.5:654',
      connState: ConnState.Unreachable,
      connType: ConnType.Reverse,
      projectId: 'ID1',
      projectRole: 'Scan',
      serialIsValid: true,
    },
    {
      id: '13:14:15:16:17',
      deviceLabel: 'Pressa1',
      connUri: '192.168.1.31:8089',
      connState: ConnState.Invalid,
      connType: ConnType.Normal,
      serialIsValid: true,
    },
    {
      id: '14:15:16:17:18',
      deviceLabel: 'Pressa2',
      connUri: '192.168.1.32:8088',
      connState: ConnState.Offline,
      connType: ConnType.Normal,
      serialIsValid: true,
    },
    {
      id: '15:16:17:18:19',
      deviceLabel: 'Pressa3',
      connUri: '192.168.1.33:8000',
      connState: ConnState.Connected,
      connType: ConnType.Normal,
      serialIsValid: true,
    },
  ];

  findProjectFolderById = (nodes: ProjectTree.Node[], id: string): ProjectTree.Folder | null => {
    for (const node of nodes) {
      if ('folder_id' in node && node.folder_id === id) {
        return node;
      } else if ('children' in node) {
        const found = this.findProjectFolderById(node.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  removeProjectFolderById(nodes: ProjectTree.Node[], id: string): ProjectTree.Node[] {
    return nodes.filter((node) => {
      if ((node as ProjectTree.Folder).folder_id === id) {
        return false; // Remove the node
      }

      if ((node as ProjectTree.Folder).children) {
        (node as ProjectTree.Folder).children = this.removeProjectFolderById((node as ProjectTree.Folder).children, id);
      }

      return true; // Keep the node
    });
  }

  /* mc*** metodo non più necessario
  ********************************************************************************
  editProjectFolderById(nodes: ProjectTree.Node[], id: string, name: string) {
    for (const node of nodes) {
      if ('folder_id' in node && node.folder_id === id) {
        if (name) {
          node.name = name;
        }
        return true; // Nodo trovato e nome cambiato
      } else if ('children' in node) {
        if (this.editProjectFolderById(node.children, id, name)) {
          return true; // Nodo trovato in un sottolivello
        }
      }
    }
    return false; // Nodo non trovato in questo livello
    ******************************************************************************
  } */

  findProjectById = (nodes: ProjectTree.Node[], id: string): ProjectTree.Project | null => {
    for (const node of nodes) {
      if ('project_id' in node && node.project_id === id) {
        return node;
      } else if ('children' in node) {
        const found = this.findProjectById(node.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  removeProjectById(nodes: ProjectTree.Node[], project_id: string): ProjectTree.Node[] {
    return nodes.filter((node) => {
      if ((node as ProjectTree.Project).project_id === project_id) {
        return false; // Remove the node
      }

      if ((node as ProjectTree.Folder).children) {
        (node as ProjectTree.Folder).children = this.removeProjectById(
          (node as ProjectTree.Folder).children,
          project_id
        );
      }

      return true; // Keep the node
    });
  }

  /* mc*** metodo non più necessario
  ******************************************************************************** 
  editProjectById(nodes: ProjectTree.Node[], project_id: string, options: { description?: string }) {
    for (const node of nodes) {
      if ('project_id' in node && node.project_id === project_id) {
        if (options.description) {
          node.description = options.description;
        }
        return true;
      } else if ('children' in node) {
        if (this.editProjectById(node.children, project_id, options)) {
          return true;
        }
      }
    }
    return false; // Nodo non trovato in questo livello
    ****************************************************************************
  } */

  removeDocumentRecursive = (nodes: Project.ProjectNode[], documentId: string): boolean => {
    for (let i = 0; i < nodes.length; ++i) {
      const node = nodes[i];

      if (node.nodeId.documentId === documentId) {
        nodes.splice(i, 1);
        return true;
      }

      // If the node is a folder, recurse into its children
      if ('children' in node) {
        const removed = this.removeDocumentRecursive(node.children, documentId);
        if (removed) {
          return true;
        }
      }
    }
    return false;
  };

  removeNode(projectId: string, documentId: string) {
    const currProject = this.openedProjects[projectId];
    if (currProject && currProject.structure) {
      let result = false;
      for (const key of Object.keys(currProject.structure)) {
        result = this.removeDocumentRecursive(currProject.structure[key], documentId);
        if (result) break;
      }

      this.notifyStructure(projectId);
      this.syncDB();
    }
  }

  hasFolderOpenedProjects(children: ProjectTree.Node[]): boolean {
    const searchInFolder = (nodes: ProjectTree.Node[]) => {
      for (const node of nodes) {
        if ('project_id' in node) {
          if (this.openedProjects[node.project_id] !== undefined) return true;
        } else if ('folder_id' in node) {
          searchInFolder(node.children);
        }
      }
    };

    if (children.length > 0) {
      searchInFolder(children);
    } else return false;
  }

  findNodeSectionId(nodeId: Project.ProjectNodeKey): string {
    const currProject: Project.Project = this.openedProjects[nodeId.projectId];
    let sectionId = '';
    let node: Project.ProjectNode | null = null;

    if (currProject && currProject.structure) {
      if (currProject.structure[nodeId.documentId]) {
        sectionId = nodeId.documentId;
      } else {
        for (const key of Object.keys(currProject.structure)) {
          node = this.findNode(currProject.structure[key], nodeId.documentId);
          if (node) {
            sectionId = key;
            break;
          }
        }
      }
    }

    return sectionId;
  }

  updateResourceToMove(resourceToUpdate: Project.ResourceTreeElement, newId: string): void {
    const updateId = (resource: Project.ResourceTreeElement, newId: string) => {
      const resourceIdArray = (resource.data.id as string).split('-');
      if (resourceIdArray.length === 2) {
        resource.data.id = `${resourceIdArray[0]}-${newId}`;
      }
    };

    const updateChildren = (resource: Project.ResourceTreeElement, newId: string) => {
      if (resource.children) {
        resource.children.forEach((child) => {
          updateId(child, newId);
          updateChildren(child, newId);
        });
      }
    };

    updateId(resourceToUpdate, newId);
    updateChildren(resourceToUpdate, newId);
  }

  updateMetadataToMove(metadataToUpdate: Project.ViewMetadata, newId: string): void {
    const updateId = (item: any, newId: string) => {
      const metadataIdArray = item.id.split('-');
      if (metadataIdArray.length === 2) {
        item.id = `${metadataIdArray[0]}-${newId}`;
      }
    };

    const updateChildren = (metadata: Project.ViewMetadata, newId: string) => {
      if (metadata.children) {
        metadata.children.forEach((child: any) => {
          updateId(child, newId);
        });
      }
    };

    const metadataIdArray = metadataToUpdate.id.split('-');
    if (metadataIdArray.length === 2) {
      metadataToUpdate.id = `${metadataIdArray[0]}-${newId}`;
    }
    updateChildren(metadataToUpdate, newId);
  }

  updateNodeToMove(
    nodeToUpdate: Project.ProjectNode,
    nodeId: Project.ProjectNodeKey,
    destinationId: Project.ProjectNodeKey
  ): Project.ProjectNode {
    const nodeToReturn: Project.ProjectNode = { ...nodeToUpdate };

    if (nodeId.projectId !== destinationId.projectId) {
      const newNodeId: Project.ProjectNodeKey = {
        projectId: destinationId.projectId,
        documentId: this.isFolder(nodeToReturn) ? this.allocNewFolderId() : this.allocNewDocumentId(),
      };
      nodeToReturn.nodeId = newNodeId;

      // for the documents: change also the id of the resource and metadata
      if (this.isDocument(nodeToReturn)) {
        if (nodeToReturn.data.resourceTree) {
          nodeToReturn.data.resourceTree.map((element) => this.updateResourceToMove(element, newNodeId.documentId));
        }
        if (nodeToReturn.data.metadata) {
          this.updateMetadataToMove(nodeToReturn.data.metadata, newNodeId.documentId);
        }
      }

      // for the folders: change recursivelly
      if (this.isFolder(nodeToReturn)) {
        nodeToReturn.children = nodeToReturn.children.map((child) =>
          this.updateNodeToMove(child, child.nodeId, destinationId)
        );
      }
    }

    return nodeToReturn;
  }

  authData?: AuthData;

  // just forward arguments to the superclass
  constructor(__url: string, options: { __logger?: Logger; __pingInterval?: number }) {
    // register a default no-op handler
    this.setEventHandler({});
  }

  // Event handling
  setEventHandler(handler: EventHandler): void {
    this.eventHandler = handler;
  }

  // Authentication

  /* getAuthData(): AuthData | undefined {
    return this.authData;
  }

  async authenticate(__domain: string, __username: string, __password: string): Promise<AuthData> {
    return Promise.resolve({
      domain: { id: 1, name: 'test' },
      user: { username: 'test', level: 1, fullname: 'test' },
    });
  } */

  // User management

  async listUsers(__filter?: string): Promise<Array<User>> {
    throw Error('Non implementato');
  }

  async getUser(__username: string): Promise<User> {
    throw Error('Non implementato');
  }

  async createUser(__user: User): Promise<User> {
    throw Error('Non implementato');
  }

  async editUser(__user: User): Promise<User> {
    throw Error('Non implementato');
  }

  async removeUser(__username: string): Promise<void> {
    throw Error('Non implementato');
  }

  // Project tree manager

  async getProjectTree(): Promise<ProjectTree.RootFolder> {
    // return this.projectTree;
    throw Error('Implementato!');
  }

  async createProjectFolder(name: string, parentId: string): Promise<string> {
    /* const match = name.match(/(\d+)$/);
    const key = match ? parseInt(match[0], 10) : null;
    const folder: ProjectTree.Folder = {
      name,
      folder_id: key,
      children: [],
    };

    const parentFolder = this.findProjectFolderById(this.projectTree, parentId);
    if (!parentFolder) {
      this.projectTree.push(folder);
    } else {
      if (position != null && position >= 0 && position < parentFolder.children.length) {
        parentFolder.children.splice(position, 0, folder);
      } else {
        parentFolder.children.push(folder);
      }
    }
    this.syncDB();
    return Promise.resolve(folder.folder_id); */
    throw Error('Implementato!');
  }

  async removeProjectFolder(id: string): Promise<void> {
    this.projectTree = this.removeProjectFolderById(this.projectTree, id);
    this.syncDB();
  }

  async renameProjectFolder(id: string, name: string): Promise<void> {
    /* this.editProjectFolderById(this.projectTree, id, name);
    this.syncDB(); */
    throw Error('Implementato!');
  }

  async moveProjectFolder(id: string, parentId: string, position: number): Promise<void> {
    // search the folder to move
    const folderToMove = this.findProjectFolderById(this.projectTree, id);
    if (folderToMove) {
      // check no opened project
      let moveFolder = true;
      const hasOpenedProjects = this.hasFolderOpenedProjects(folderToMove.children);
      if (hasOpenedProjects !== null) {
        moveFolder = false;
        alert('un progetto del folder risulta aperto. Impossibile spostarlo');
      }

      if (moveFolder) {
        // remove from the old folder and add to the new folder
        this.projectTree = this.removeProjectFolderById(this.projectTree, id);

        // search the destination folder
        if (parentId === '') {
          this.projectTree.push(folderToMove);
        } else {
          const destinationFolder = this.findProjectFolderById(this.projectTree, parentId);
          if (destinationFolder) {
            destinationFolder.children.push(folderToMove);
          }
        }
      }
    }
    this.syncDB();
  }

  async createProject(description: string, parentId: string, position?: number): Promise<string> {
    /* const match = description.match(/(\d+)$/);
    const key = `ID${match ? parseInt(match[0], 10) : null}`;
    const project: Project.Project = {
      description,
      id: key,
      structure: {},
    };
    // create structure
    documentEditorsConfig.forEach((config) => {
      project.structure[config.section] = [];
    });
    this.projectList[key] = project;

    const tmpTreeProject: ProjectTree.Project = {
      project_id: project.id,
      description: project.description,
    };

    const parentFolder = this.findProjectFolderById(this.projectTree, parentId);
    if (!parentFolder) {
      this.projectTree.push(tmpTreeProject);
    } else {
      if (position != null && position >= 0 && position < parentFolder.children.length) {
        parentFolder.children.splice(position, 0, tmpTreeProject);
      } else {
        parentFolder.children.push(tmpTreeProject);
      }
    }

    this.syncDB();
    return Promise.resolve(project.id); */
    throw Error('Implementato!');
  }

  async removeProject(id: string): Promise<void> {
    delete this.projectList[id];
    this.projectTree = this.removeProjectById(this.projectTree, id);
    this.syncDB();
  }

  async renameProject(id: string, description?: string ): Promise<void> {
    /* this.projectList[id].description = options.description;
    this.editProjectById(this.projectTree, id, options);
    this.syncDB(); */
    throw Error('Implementato!');
  }

  async moveProject(id: string, parentId: string, position: number): Promise<void> {
    // search the project to move
    const projectToMove = this.findProjectById(this.projectTree, id);
    if (projectToMove) {
      // check no opened project
      let moveProject = true;
      if (this.openedProjects[id] !== undefined && this.openedProjects[id].id === projectToMove.project_id) {
        moveProject = false;
        alert('progetto aperto. Impossibile spostarlo');
      }

      if (moveProject) {
        // remove from the old folder and add to the new folder
        this.projectTree = this.removeProjectById(this.projectTree, id);

        // search the destination folder
        if (parentId === '') {
          this.projectTree.push(projectToMove);
        } else {
          const destinationFolder = this.findProjectFolderById(this.projectTree, parentId);
          if (destinationFolder) {
            destinationFolder.children.push(projectToMove);
          }
        }
      }
    }
    this.syncDB();
  }

  // Project manager

  async openProject(id: string): Promise<Project.Project> {
    const currProjectListItem = this.projectList[id];
    this.openedProjects[currProjectListItem.id] = currProjectListItem;
    return Promise.resolve(this.cleanProjectTree(currProjectListItem));
  }

  async closeProject(id: string): Promise<void> {
    return Promise.resolve();
  }

  async getProjectStructure(id: string): Promise<Project.ProjectStructure> {
    throw Error('Non implementato');
  }

  // Project editor

  async createFolder(projectId: string, name: string, parentId: string): Promise<string> {
    /* const parentList = this.findDocumentParentList(projectId, parentId);
    const newId = { projectId, documentId: this.allocNewFolderId() };
    if (parentList) {
      parentList.push({
        nodeId: newId,
        name: name,
        children: [],
      });
      this.notifyStructure(projectId);
    }
    this.syncDB();
    return Promise.resolve(newId.documentId); */
    throw Error('Implementato!');
  }

  async removeFolder(nodeId: Project.ProjectNodeKey): Promise<void> {
    this.removeNode(nodeId.projectId, nodeId.documentId);
  }

  async createDocument(
    projectId: string,
    type: string,
    name: string,
    parentId: string,
    position?: number
  ): Promise<string> {
    /* const parentList = this.findDocumentParentList(projectId, parentId);
    const newId = { projectId, documentId: this.allocNewDocumentId() };

    const item: Project.ProjectNode = {
      nodeId: newId,
      name: name,
      type: type,
      data: {},
    };

    switch (type) {
      case 'tag':
        item.data.resourceTree = [];
        break;
      case 'alarm':
        item.data.resourceTree = [];
        break;
      case 'db':
      case 'recipe':
        item.data.resourceTree = [
          {
            name: 'Events',
            data: { id: 'evt-' + newId.documentId },
            children: [
              { name: 'onLoad', data: { id: 'evt-' + newId.documentId + '_OnLoad' }, pinType: Project.PinType.InOut },
              { name: 'onSave', data: { id: 'evt-' + newId.documentId + '_OnSave' }, pinType: Project.PinType.InOut },
              { name: 'onDelete', data: { id: 'evt-' + newId.documentId + '_OnDelete' }, pinType: Project.PinType.InOut },
            ],
          },
          {
            name: 'Fields',
            data: { id: 'fld-' + newId.documentId },
            children: [],
          },
        ];

        if (type === 'recipe') {
          const currentMetadata: Project.DatabaseMetadata = {
            keys: [],
            connectionString: 'pippo:paperino',
          };

          item.data.metadata = currentMetadata;
        }
        break;
    }

    if (parentList) {
      parentList.push(item);
      this.notifyStructure(projectId);
    }
    this.syncDB();
    return Promise.resolve(newId.documentId); */
    throw Error('Implementato!');
  }

  async removeDocument(nodeId: Project.ProjectNodeKey): Promise<void> {
    // this.removeNode(nodeId.projectId, nodeId.documentId);
    throw Error('Implementato!');
  }

  async moveNode(
    nodeId: Project.ProjectNodeKey,
    destinationId: Project.ProjectNodeKey,
    position: number
  ): Promise<void> {
    // search the node to move
    let nodeToMove = this.findProjectNode(nodeId.projectId, nodeId.documentId);
    if (nodeToMove) {
      // check no opened project
      let continueToMove = true;
      if (this.openedDocuments !== undefined && this.openedDocuments[JSON.stringify(nodeToMove.nodeId)]) {
        continueToMove = false;
        alert('documento aperto. Impossibile spostarlo');
      }

      if (continueToMove) {
        // search the destination folder
        if (destinationId.documentId) {
          // check if the destination section is the same of the start section
          const sourceSectionId = this.findNodeSectionId(nodeId);
          const destinationSectionId = this.findNodeSectionId(destinationId);
          if (sourceSectionId === destinationSectionId) {
            // remove from the old folder and add to the new folder
            this.removeNode(nodeId.projectId, nodeId.documentId);
            // update id if the start and destination projects are different
            nodeToMove = this.updateNodeToMove(nodeToMove, nodeId, destinationId);
            // add to the new destination
            const currProject = this.openedProjects[destinationId.projectId];
            if (currProject && currProject.structure) {
              const section = currProject.structure[destinationId.documentId];
              if (section) {
                section.push(nodeToMove);
              } else {
                const destinationFolder = this.findProjectNode(destinationId.projectId, destinationId.documentId);
                if (destinationFolder && this.isFolder(destinationFolder)) {
                  destinationFolder.children.push(nodeToMove);
                }
              }
            }
          }
        }
      }
    }

    this.notifyStructure(nodeId.projectId);
    if (destinationId) {
      this.notifyStructure(destinationId.projectId);
    }
    this.syncDB();
  }

  async renameNode(nodeId: Project.ProjectNodeKey, name: string): Promise<void> {
    const node = this.findProjectNode(nodeId.projectId, nodeId.documentId);
    if (node) {
      node.name = name;
      this.notifyStructure(nodeId.projectId);
      this.syncDB();
    }
  }

  async openDocument(nodeId: Project.ProjectNodeKey): Promise<Project.OpenedDocument> {
    /* const document = this.findProjectNode(nodeId.projectId, nodeId.documentId);
    if (this.isDocument(document)) {
      const nodeIdKey = JSON.stringify(document.nodeId);
      if (!this.openedDocuments[nodeIdKey]) {
        this.openedDocuments[nodeIdKey] = {
          doc: this.clone<Project.Document>(document),
          editorStatus: {},
          modified: false,
        };
      }
      await this.delay(100);
      return Promise.resolve(this.clone(this.openedDocuments[nodeIdKey]));
    }
    throw Error('Documento non trovato'); */
    throw Error('Implementato!');
  }

  /** Aggiorno soltanto il ProjectData */
  async updateOpenedDocument(nodeId: Project.ProjectNodeKey, data: Project.DocumentData): Promise<void> {
    /* if (this.openedProjects[nodeId.projectId]) {
      const nodeIdKey = JSON.stringify(nodeId);
      if (this.openedDocuments[nodeIdKey]) {
        this.openedDocuments[nodeIdKey].doc.data = this.clone<Project.DocumentData>(data);
        this.openedDocuments[nodeIdKey].modified = true;

        // update project structure with the changes of the onpened document
        const currProject = this.openedProjects[nodeId.projectId];
        if (currProject) {
          if (currProject && currProject.structure) {
            let result = false;
            for (const key of Object.keys(currProject.structure)) {
              result = this.updateProjectStructure(nodeId.documentId, data, currProject.structure[key]);
              if (result) break;
            }
          }

          // update structure and resources
          this.notifyStructure(nodeId.projectId);
        }
      }
    }

    return Promise.resolve(); */
    throw Error('Implementato!');
  }

  async updateEditorStatus(nodeId: Project.ProjectNodeKey, status: string): Promise<void> {
    if (this.openedProjects[nodeId.projectId]) {
      const nodeIdKey = JSON.stringify(nodeId);
      if (this.openedDocuments[nodeIdKey]) {
        this.openedDocuments[nodeIdKey].editorStatus = this.clone<unknown>(status);
      }
    }
  }

  async saveOpenedDocument(nodeId: Project.ProjectNodeKey): Promise<void> {
    const document = this.findProjectNode(nodeId.projectId, nodeId.documentId);
    const nodeIdKey = JSON.stringify(nodeId);
    if (document && this.isDocument(document) && this.openedDocuments[nodeIdKey]) {
      document.data = this.clone(this.openedDocuments[nodeIdKey].doc.data);
      this.notifyStructure(nodeId.projectId);
      this.syncDB();
    }
    return this.delay(50);
  }

  async resetModifiedFlag(nodeId: Project.ProjectNodeKey): Promise<void> {
    throw Error('Non implementato');
  }

  async closeOpenedDocument(nodeId: Project.ProjectNodeKey): Promise<void> {
    if (this.openedProjects[nodeId.projectId]) {
      const nodeIdKey = JSON.stringify(nodeId);
      if (this.openedDocuments[nodeIdKey]) {
        //chiusura tab Layers se aperta
        if (
          this.openedDocuments[nodeIdKey].doc.type === 'page' &&
          useLayersEditorStore.getState().isLayersEditorVisible
        ) {
          useLayersEditorStore.getState().setLayersEditorWidgets([]);
          useLayersEditorStore.getState().setLayersEditorVisible(false);
        }
        delete this.openedDocuments[nodeIdKey];
      }
    }
    return this.delay(50);
  }

  // Panels

  async listProjectPanels(projectId: string): Promise<Panel[]> {
    return this.currPanelsList;
  }

  async addPanelToProject(
    projectId: string,
    id: { serial?: string; connUri?: string },
    projectRole?: string
  ): Promise<string> {
    // cloud
    if (id.serial) {
      const updatedPanelsList = this.currPanelsList.map((element) => {
        if (element.id === id.serial) {
          return {
            ...element,
            projectRole: projectRole,
            projectId: projectId,
          };
        }
        return element;
      });
      this.currPanelsList = updatedPanelsList;
      this.eventHandler?.projectPanelsChanged?.(this.currPanelsList);
      return id.serial;
    } else {
      // new panel
      const newPanel: Panel = {
        projectId: projectId,
        projectRole: projectRole,
        id: '15:16:17:18:' + Math.floor(Math.random() * (100 - 20 + 1)) + 20,
        connState: ConnState.Connected,
        connType: ConnType.Reverse,
        serialIsValid: false,
        connUri: id.connUri,
      };
      this.currPanelsList.push(newPanel);
      this.eventHandler?.projectPanelsChanged?.(this.currPanelsList);
      return newPanel.id;
    }
  }

  async editProjectPanel(
    projectId: string,
    panelId: string,
    what: { newConnUri?: string; newRole?: string }
  ): Promise<void> {
    const updatedPanelsList = this.currPanelsList.map((panel) => {
      if (panel.id === panelId && panel.projectId === projectId) {
        return {
          ...panel,
          projectRole: what?.newRole,
          connUri: what?.newConnUri,
        };
      }
      return panel;
    });
    this.currPanelsList = updatedPanelsList;

    this.eventHandler?.projectPanelsChanged?.(this.currPanelsList);
  }

  async removePanelFromProject(projectId: string, panelId: string): Promise<void> {
    this.currPanelsList.forEach((panel, index) => {
      if (panel.id === panelId && panel.projectId === projectId) {
        this.currPanelsList.splice(index, 1);

        const randomId = '15:16:17:18:' + Math.floor(Math.random() * (100 - 20 + 1)) + 20;

        // recreate a new cloud panel for the list. It is only for test
        this.currPanelsList.push({
          id: randomId,
          deviceLabel: 'Pressa3',
          connState: ConnState.Offline,
          connType: ConnType.Normal,
          serialIsValid: true,
          connUri: '192.168.1.3:8080',
        });
      }
    });
    this.eventHandler?.projectPanelsChanged?.(this.currPanelsList);
  }

  async retryPanelConnection(panelId: string): Promise<void> {
    const panel = this.currPanelsList.find((p) => p.id === panelId);
    if (panel) {
      const connStates = Object.values(ConnState).filter((value) => typeof value === 'number') as ConnState[];
      const randomIndex = Math.floor(Math.random() * connStates.length);
      panel.connState = connStates[randomIndex];
    }

    this.eventHandler?.projectPanelsChanged?.(this.currPanelsList);
  }

  async connectToPanel(host: string, port: number, options: { path?: string; timeout?: number } = {}): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async deployToPanels(projectId: string, panelIds: string[], test_data?: unknown): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let completedDeploys = 0;
      panelIds.forEach((panelId) => {
        this.eventHandler?.deployStatusChanged?.({
          projectId,
          panelId,
          status: DeployStatus.InProgress,
        });

        setTimeout(() => {
          const currPanel = this.currPanelsList.find((panel) => panel.id === panelId);
          if (currPanel !== undefined && currPanel.connState === ConnState.Connected) {
            // stamp a message in the console log
            let tmpConsoleMessage = 'deploy panel ' + panelId + ' of project: ' + projectId;
            if (test_data) {
              tmpConsoleMessage += ', data: ' + test_data;
            }
            console.log(tmpConsoleMessage);
            this.eventHandler?.deployStatusChanged?.({
              projectId,
              panelId,
              status: DeployStatus.Success,
            });
          } else {
            this.eventHandler?.deployStatusChanged?.({
              projectId,
              panelId,
              status: DeployStatus.Failure,
            });
          }

          completedDeploys++;
          if (completedDeploys === panelIds.length) {
            resolve();
          }
        }, 3000);
      });
    });
  }

  // Resources

  async getDeployData(projectId: string, role: string): Promise<DeployData> {
    throw Error('Non implementato');
  }

  async syncResources(projectId: string, serial: string): Promise<SyncStats> {
    throw Error('Non implementato');
  }

  async listResources(
    projectId: string,
    options: { typeFilter?: ResourceType; nameFilter?: string } = {}
  ): Promise<Resource[]> {
    throw Error('Non implementato');
  }

  async removeResource(projectId: string, id: number): Promise<void> {
    throw Error('Non implementato');
  }

  async createResource(projectId: string, type: ResourceType, contents: File, label?: string): Promise<Resource> {
    throw Error('Non implementato');
  }

  async updateResource(projectId: string, id: number, what: { label?: string; contents?: File }): Promise<Resource> {
    throw Error('Non implementato');
  }

  // Database

  async getDatabaseStructureData(connectionString: string): Promise<DatabaseInfos> {
    const tables: Table[] = [
      {
        tableName: 'users',
        fields: [
          {
            fieldName: 'id',
            type: 'INTEGER',
            key: true,
          },
          {
            fieldName: 'username',
            type: 'VARCHAR(255)',
            key: false,
          },
        ],
      },
      {
        tableName: 'orders',
        fields: [
          {
            fieldName: 'id',
            type: 'INTEGER',
            key: true,
          },
          {
            fieldName: 'orderNumber',
            type: 'VARCHAR(255)',
            key: true,
          },
        ],
      },
    ];

    return {
      tables,
    };
  }

  /**
   * Funzione Get dei file/cartelle
   * @returns fileSystem contenente file e cartelle
   */
  async getBinaryFiles(): Promise<Project.BinaryFolder> {
    return this.fileSystem;
  }

  /**
   * Ricerca ricorsiva della cartella di destinazione
   * @param currentFolder cartella attuale
   * @param path percorso della cartella da cercare
   * @returns
   */
  findFolder = (currentFolder: Project.BinaryFolder, path: string): Project.BinaryFolder | undefined => {
    //nome della cartella effettiva da cercare
    const folderName = path.split('/').filter(Boolean).pop();
    if (!folderName) {
      return undefined;
    }

    //funzione ricorsiva di ricerca
    const searchFolder = (folder: Project.BinaryFolder): Project.BinaryFolder | undefined => {
      if (folder.name === folderName) {
        return folder;
      }
      for (const subFolder of folder.subFolders || []) {
        const found = searchFolder(subFolder);
        if (found) {
          return found;
        }
      }
      return undefined;
    };

    return searchFolder(currentFolder);
  };

  /**
   * Salvataggio file
   * @param path cartella di destinazione
   * @param file file da inserire
   * @returns true se l'inserimento è andato a buon fine, altrimenti false
   */
  async addBinaryFile(path: string, file: Project.BinaryFile): Promise<boolean> {
    if (path === 'root') {
      this.fileSystem.files.push(file);
      return true;
    } else {
      const targetFolder = this.findFolder(this.fileSystem, path);
      if (targetFolder) {
        targetFolder.files = targetFolder.files || [];
        targetFolder.files.push(file);
        return true;
      } else {
        console.warn('Cartella non trovata: ', path);
        return false;
      }
    }
  }

  /**
   * Cancellazione file
   * @param fileId id del file da eliminare
   * @returns true se la cancellazione è andata a buon fine, altrimenti false
   */
  async removeBinaryFile(fileId: string): Promise<boolean> {
    const removeFileFromFolder = (folder: Project.BinaryFolder): boolean => {
      const fileIndex = folder.files?.findIndex((file) => file.id === fileId);
      if (fileIndex !== undefined && fileIndex >= 0) {
        folder.files?.splice(fileIndex, 1);
        return true;
      }

      //ricerca nelle subfolders
      for (const subFolder of folder.subFolders || []) {
        const removed = removeFileFromFolder(subFolder);
        if (removed) {
          return true;
        }
      }

      return false;
    };

    if (removeFileFromFolder(this.fileSystem)) {
      localStorage.setItem('FileSystem', JSON.stringify(this.fileSystem));
      return true;
    } else {
      console.warn('File non trovato per rimozione:', fileId);
      return false;
    }
  }

  /**
   * Get del file selezionato
   * @param fileId id del file da cercare
   * @returns file trovato oppure undefined
   */
  async getSelectedFile(fileId: string): Promise<Project.BinaryFile | undefined> {
    const findFileInFolder = (folder: Project.BinaryFolder): Project.BinaryFile | undefined => {
      const file = folder.files?.find((file) => file.id === fileId);
      if (file) {
        return file;
      }

      for (const subFolder of folder.subFolders || []) {
        const foundFile = findFileInFolder(subFolder);
        if (foundFile) {
          return foundFile;
        }
      }

      return undefined;
    };

    return findFileInFolder(this.fileSystem);
  }

  /**
   * Ricerca dell'ID massimo presente nel file system
   * @returns id max
   */
  getMaxFileId(): number {
    const findMaxIdInFolder = (folder: Project.BinaryFolder): number => {
      let maxId = 0;
      //controllo in files
      for (const file of folder.files || []) {
        const idMatch = file.id.match(/\d+$/);
        const idNum = idMatch ? parseInt(idMatch[0], 10) : 0;
        maxId = Math.max(maxId, idNum);
      }

      //controllo in subfolders
      for (const subFolder of folder.subFolders || []) {
        const subFolderMaxId = findMaxIdInFolder(subFolder);
        maxId = Math.max(maxId, subFolderMaxId);
      }

      return maxId;
    };

    return findMaxIdInFolder(this.fileSystem);
  }
}
