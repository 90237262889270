import { DocumentEditorFactory } from '@services/documentEditorFactory';
import { IpcState, useIpcStore, webClient } from '@stores/ipcStore';
import { Login } from './Login';
import { documentEditorsConfig } from '@config/documentEditor';
import { useLayoutStore } from '@stores/layoutStore';
import { useProjectStore } from '@stores/projectStore';
import MainApp from './MainApp';
import React, { useEffect, useState } from 'react';

documentEditorsConfig.forEach((config) => {
  DocumentEditorFactory.registerDocumentBuilder(config.type, config.editor);
});

const App: React.FC = () => {
  const [user, setUser] = useState<{
    account: string;
    username: string;
    password: string;
  } | null>(null);
  const [fadeIn, setFadeIn] = useState(false);
  const [logged, setLogged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const setAuthData = useIpcStore((state: IpcState) => state.setAuthData);

  const { setOpenedProjects } = useProjectStore();
  const activateTab = useLayoutStore((state) => state.activateTab);

  useEffect(() => {
    if (logged) {
      setFadeIn(true);
    }
  }, [user, logged]);

  useEffect(() => {
    const account = localStorage.getItem('userAccount');
    const username = localStorage.getItem('userName');
    const password = localStorage.getItem('password');
    if (account && username) {
      setUser({ account, username, password });
    }
  }, []);

  const handleLoginSuccess = (account: string, username: string, password: string) => {
    webClient
      .authenticate(account, username, password)
      .then((result) => {
        setAuthData(result);
        setLogged(true);

        // load the structure of all opened projects
        if (result.user.openProjects && result.user.openProjects.length > 0) {
          result.user.openProjects.forEach(async (projectId) => {
            try {
              const project = await webClient.openProject(projectId);
              setOpenedProjects(project);
            } catch (error) {
              console.log('openProject', error);
              alert('openProject error: ' + error);
            }
          });
          activateTab('ProjectManager');
        }
      })
      .catch((error) => {
        setErrorMessage(`${error.code}:${error.message}`);
      });
  };

  return (
    <div>
      {!logged ? (
        <Login
          onLoginSuccess={handleLoginSuccess}
          errorMessage={errorMessage}
          savedUsername={user?.username}
          savedAccount={user?.account}
          savedPassword={user?.password}
        />
      ) : (
        <div className={fadeIn ? 'fade-enter-active' : 'fade-enter'}>
          <MainApp />
        </div>
      )}
    </div>
  );
};

export default App;
