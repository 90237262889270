import { PropertiesManager } from '../properties-manager';
import { WidgetContainer } from '../widget-container';
import { WidgetDictionary } from '../widget-dictionary';
import { WidgetFactory } from '../widget-factory';
import { WidgetProperties } from '../widget';

export class Accordion extends WidgetContainer {
  private openedIndex: number | null = null;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  detach() {
    super.detach();
  }

  attach(): void {
    this.domElement.classList.add('fast-accordion');
  }

  // chiusura di tutti gli header
  closeHeaders() {
    const accordionItems = this.domElement.querySelectorAll('.accordion-content');
    const arrows = this.domElement.querySelectorAll('.accordion-arrow');

    accordionItems.forEach((item: HTMLElement) => {
      item.style.maxHeight = '0';
      item.style.display = 'none';
    });

    arrows.forEach((arrow: HTMLElement) => {
      arrow.style.transform = 'rotate(0deg)';
    });
  }

  setProperties(properties: WidgetProperties): void {
    for (const key in properties) {
      const data = PropertiesManager.getData(properties, key);

      switch (key) {
        case 'OpenedItem':
          this.openedIndex =
            data !== undefined && !isNaN(parseInt(data)) && parseInt(data) > 0 ? parseInt(data) - 1 : null;
          this.closeHeaders();
          this.domElement.querySelectorAll('.accordion-item').forEach((accordionItem: Element, index: number) => {
            const contentDiv = accordionItem.querySelector('.accordion-content') as HTMLElement;
            const arrowDiv = accordionItem.querySelector('.accordion-arrow') as HTMLElement;

            if (this.openedIndex === index) {
              contentDiv.style.display = 'block';
              contentDiv.style.maxHeight = '160px';
              arrowDiv.style.transform = 'rotate(90deg)';
            }
          });
          break;
        case 'AccordionIsHorizontal':
          if (data) {
            this.domElement.classList.add('horizontal');
          } else {
            this.domElement.classList.remove('horizontal');
          }
          break;
        default:
          if (key.includes('Font')) {
            this.setFontProperties(properties, this.domElement, 'Accordion');
          } else {
            this.setDefaultProperties(properties);
          }
      }
    }
    this.setWidgetContainerProperties(properties);
  }

  setWidgetContainerProperties(properties: WidgetProperties): void {
    if (!properties) {
      console.error('Properties is undefined or null');
      return;
    }

    super.setProperties(properties);
    console.log(properties);

    // Gestione delle proprietà con chiavi composte
    let itemsModified = false; // Flag per tenere traccia delle modifiche a Items

    if (properties) {
      Object.keys(properties).forEach((key) => {
        if (key.includes('.')) {
          const splitKey = key.split('.');
          if (splitKey.length > 2) {
            console.log(`Valore della chiave composta: ${splitKey[2]}:${properties[key]}`);
            const itemId = splitKey[1]; // Utilizza itemId direttamente dal nome della proprietà
            const propertyKey = splitKey[2];
            const value = properties[key];

            if (!properties[itemId]) {
              properties[itemId] = {};
            }
            properties[itemId][propertyKey] = value;
            itemsModified = true; // Indica che abbiamo apportato modifiche

            // Ricrea solo l'elemento modificato per questo item usando l'id univoco
            const updatedItem = this.createAccordionItem(properties[itemId], itemId);

            // Cerca e sostituisce l'elemento aggiornato nel DOM usando `data-id`
            const existingItem = this.domElement.querySelector(`.accordion-item[data-id="${itemId}"]`);
            if (existingItem) {
              this.domElement.replaceChild(updatedItem, existingItem);
            } else {
              this.domElement.appendChild(updatedItem);
            }
          }
        }
      });

      // Se non ci sono modifiche dirette a items, costruisci l'intero componente
      if (!itemsModified && 'Items' in properties && typeof properties.Items === 'object') {
        const items = properties.Items;
        console.log(items);

        // Pulizia degli elementi DOM esistenti
        while (this.domElement.firstChild) {
          this.domElement.removeChild(this.domElement.firstChild);
        }

        this.detachChildren();
        Object.keys(items).forEach((key) => {
          const viewProperties = items[key];
          const accordionItem = this.createAccordionItem(viewProperties, key);
          this.domElement.appendChild(accordionItem);
        });
      } else if ('HTextAlign' in properties) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.domElement.querySelectorAll('.accordion-text').forEach((element: any) => {
          if (properties['HTextAlign'] === 1) {
            element.style['textAlign'] = 'center';
          } else if (properties['HTextAlign'] === 2) {
            element.style['textAlign'] = 'right';
          } else {
            element.style['textAlign'] = 'left';
          }
        });
      }
    }
  }

  createAccordionItem(viewProperties: any, id: string): HTMLElement {
    // Inizializzazione elementi
    const accordionItem = document.createElement('div');
    accordionItem.classList.add('accordion-item');
    accordionItem.setAttribute('data-id', id); // Aggiungi attributo data-id per identificare l'item in modo univoco

    const headerDiv = document.createElement('div');
    headerDiv.classList.add('accordion-header');

    const textDiv = document.createElement('div');
    textDiv.textContent = viewProperties['caption'];
    textDiv.classList.add('accordion-text');

    if ('Header' in viewProperties) {
      const headerContent = document.createElement('div');
      headerContent.classList.add('accordion-header-content');
      headerContent.textContent = viewProperties['Header'];
      headerDiv.appendChild(headerContent);
    }

    const arrowDiv = document.createElement('span');
    arrowDiv.classList.add('accordion-arrow');
    arrowDiv.innerHTML = '&#9654;';
    arrowDiv.style.transform = 'rotate(0deg)';

    headerDiv.appendChild(textDiv);
    headerDiv.appendChild(arrowDiv);

    const contentDiv = document.createElement('div');
    contentDiv.classList.add('accordion-content');
    contentDiv.style.display = 'none';

    // Evento click
    const toggleHeader = () => {
      // Estrai l'indice numerico dall'id rimuovendo 'item_'
      const itemIndex = parseInt(id.replace('item_', ''), 10) - 1;
      if (isNaN(itemIndex)) {
        console.error(`Impossibile determinare l'indice dall'id: ${id}`);
        return; // Se non è possibile ottenere un numero valido, termina l'operazione
      }
    
      // Verifica se l'elemento corrente è aperto
      const isOpen = this.openedIndex === itemIndex;
    
      if (isOpen) {
        // Se l'elemento è già aperto, lo chiude
        contentDiv.style.display = 'none';
        arrowDiv.style.transform = 'rotate(0deg)';
        contentDiv.style.maxHeight = '0';
        this.openedIndex = null; // Nessun elemento è aperto
      } else {
        // Se l'elemento non è aperto, chiude tutti e apre questo
        this.closeHeaders();
        contentDiv.style.display = 'block';
        arrowDiv.style.transform = 'rotate(90deg)';
        contentDiv.style.maxHeight = '160px';
        this.openedIndex = itemIndex; // Imposta questo elemento come aperto
        this.sendEvent('ItemClick', itemIndex + 1, true); // Modifica proprietà per il click sull'elemento
      }
    };
    headerDiv.addEventListener('click', toggleHeader);
    
    headerDiv.addEventListener('click', toggleHeader);

    // Unione elementi
    accordionItem.appendChild(headerDiv);
    accordionItem.appendChild(contentDiv);

    // Applicazione proprietà specifiche come Width
    if ('Width' in viewProperties) {
      accordionItem.style.width = `${viewProperties['Width']}%`;
    }

    return accordionItem;
  }

  setBaseWidgetProperties(properties: WidgetProperties): void {
    for (const key of Object.keys(properties)) {
      if (![undefined, null].includes(properties[key])) {
        if (key === 'id') {
          this.domElement.id = properties['id'];
          WidgetDictionary.registerWidget(properties['id'], this);
        } else if (key !== 'type') {
          this.setCurrentProperties(properties, key);
        }
      }
    }
  }
}
