import { AlignItems } from '@components/document-editors/PageDocumentEditor/view-manager/widget-handler/base-widget';
import { Project } from '@api';
import { PropertiesConfig } from './interface';
import { TabName, TextPosition } from './enum';
import { merge } from './widgetData';
import ButtonBar from '@components/property-edit/component/SelectButtonComponent';
import MultiPropertyEditor from '@components/property-edit/component/MultiPropertyComponent';
import NumberSlider from '@components/property-edit/component/NumberSliderComponent';
import ToggleButtonBar from '@components/property-edit/component/ToggleButtonComponent';

export const transformField = (): PropertiesConfig => ({
  properties: {
    Offset: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      caption: 'Offset',
      captionPosition: TextPosition.Left,
      pinType: Project.PinType.None,
      editor: MultiPropertyEditor,
      editorOptions: {
        subProperties: merge({
          properties: {
            OffsetX: {
              editor: NumberSlider,
              editorOptions: {
                minValue: -1000,
                maxValue: 1000,
              },
              defaultData: 0,
              caption: 'Offset X',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },

            OffsetY: {
              editor: NumberSlider,
              editorOptions: {
                minValue: -1000,
                maxValue: 1000,
              },
              defaultData: 0,
              caption: 'Offset Y',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },
          },
        }),
      },
    },

    Ruota: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      caption: 'Ruota',
      captionPosition: TextPosition.Left,
      pinType: Project.PinType.None,
      editor: MultiPropertyEditor,
      editorOptions: {
        subProperties: merge({
          properties: {
            RotateZ: {
              editor: NumberSlider,
              editorOptions: {
                minValue: -360,
                maxValue: 360,
              },
              defaultData: 0,
              caption: 'Ruota (deg)',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
            },

            RotateX: {
              editor: NumberSlider,
              editorOptions: {
                minValue: -360,
                maxValue: 360,
              },
              defaultData: 0,
              caption: 'Ruota X (deg)',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
            },

            RotateY: {
              editor: NumberSlider,
              editorOptions: {
                minValue: -360,
                maxValue: 360,
              },
              defaultData: 0,
              caption: 'Ruota Y (deg)',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
            },

            Perspective: {
              editor: NumberSlider,
              editorOptions: {
                minValue: 1,
                maxValue: 1000,
              },
              defaultData: 0,
              caption: 'Prospettiva',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
              hasUM: true,
            },
          },
        }),
      },
    },

    Scalatura: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      caption: 'Scalatura',
      captionPosition: TextPosition.Left,
      pinType: Project.PinType.None,
      editor: MultiPropertyEditor,
      editorOptions: {
        subProperties: merge({
          properties: {
            ScaleX: {
              editor: NumberSlider,
              editorOptions: {
                minValue: 0,
                maxValue: 2,
                step: 0.1,
              },
              defaultData: 1,
              caption: 'Scala X',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
            },

            ScaleY: {
              editor: NumberSlider,
              editorOptions: {
                minValue: 0,
                maxValue: 2,
                step: 0.1,
              },
              defaultData: 1,
              caption: 'Scala Y',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
            },
          },
        }),
      },
    },

    Inclinazione: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      caption: 'Inclinazione',
      captionPosition: TextPosition.Left,
      pinType: Project.PinType.None,
      editor: MultiPropertyEditor,
      editorOptions: {
        subProperties: merge({
          properties: {
            SkewX: {
              editor: NumberSlider,
              editorOptions: {
                minValue: -360,
                maxValue: 360,
              },
              defaultData: 0,
              caption: 'Distrosione X (deg)',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
            },

            SkewY: {
              editor: NumberSlider,
              editorOptions: {
                minValue: -360,
                maxValue: 360,
              },
              defaultData: 0,
              caption: 'Distrosione Y (deg)',
              captionPosition: TextPosition.Up,
              isResponsive: true,
              pinType: Project.PinType.None,
            },
          },
        }),
      },
    },

    RiflettiOrizzontale: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      caption: 'Rifletti orizzontale',
      editor: ToggleButtonBar,
      defaultData: 0,
      pinType: Project.PinType.None,
      captionPosition: TextPosition.Left,
    },

    CapovolgiVerticale: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      caption: 'Capovolgi Verticale',
      editor: ToggleButtonBar,
      defaultData: 0,
      pinType: Project.PinType.None,
      captionPosition: TextPosition.Left,
    },

    XPuntoAncoraggio: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      defaultData: -1,
      editorOptions: {
        options: [
          { icon: 'pi pi-times', value: -1 },
          { icon: 'bi-align-start', value: AlignItems.Start },
          { icon: 'bi-align-center', value: AlignItems.Middle },
          { icon: 'bi-align-end', value: AlignItems.End },
        ],
      },
      caption: 'X Punto di ancoraggio',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      pinType: Project.PinType.None,
    },

    YPuntoAncoraggio: {
      tab: TabName.Advanced,
      section: 'Trasformazione',
      defaultData: -1,
      editorOptions: {
        options: [
          { icon: 'pi pi-times', value: -1 },
          { icon: 'bi-align-top', value: AlignItems.Start },
          { icon: 'bi-align-middle', value: AlignItems.Middle },
          { icon: 'bi-align-bottom', value: AlignItems.End },
        ],
      },
      caption: 'Y Punto di ancoraggio',
      editor: ButtonBar,
      captionPosition: TextPosition.Up,
      pinType: Project.PinType.None,
    },
  },
});
