import { Button } from 'primereact/button';
import { DocumentEditorComponent } from '@services/documentEditorFactory';
import { Field, Table } from '@api';
import { Fieldset } from 'primereact/fieldset';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Project } from '@api';
import { RecipeItemEditor } from '@components/document-editors/RecipeDocumentEditor/RecipeItemEditor';
import { Toast } from 'primereact/toast';
import { TreeLink } from '@components/tree-link/TreeLink';
import { Treeview } from '@components/common/treeview';
import { deepCopy } from '@services/utils';
import AutoFocusDiv from '@components/common/AutoFocusDiv';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const Events = ['OnLoad', 'OnSave', 'OnDelete'];

function getPathReference(objects: Project.ResourceTreeElement[], path: string[]): Project.ResourceTreeElement | null {
  const [currentPath, ...remainingPath] = path;
  const object = objects.find(({ name }) => name.toLowerCase() === currentPath.toLowerCase());

  if (!object || remainingPath.length === 0) {
    return object;
  }

  return object.children ? getPathReference(object.children, remainingPath) : null;
}

export const RecipeDocumentEditor: DocumentEditorComponent = ({
  data: documentData,
  onDataChange: onDocumentDataChange,
  documentId,
}) => {
  const [recipe, setRecipe] = useState<Table>({ fields: [] });
  const linkOverlay = useRef<OverlayPanel>(null);
  const [startResourcePoint, setStartResourcePoint] = useState<
    (Project.ResourceLinkPoint & { pinType: Project.PinType }) | undefined
  >();
  const [selectedField, setSelectedField] = useState<string | undefined>();
  const [rename, setRename] = useState<string>();

  /**
   * Caricamento effettivo della ricetta
   */
  const syncRecipeInfo = useCallback(async () => {
    const fields: Project.ResourceTreeElement[] = documentData.resourceTree[1]?.children ?? [];
    const recipeFields: Field[] = fields.map((el) => ({
      fieldName: el.name,
      type: el.type,
    }));

    setRecipe((prevRecipe) => ({
      ...prevRecipe,
      fields: recipeFields,
    }));
  }, [documentData]);

  /**
   * Chiamata a caricamento informazioni
   */
  useEffect(() => {
    syncRecipeInfo();
  }, [syncRecipeInfo]);

  const recipeTreeItems = useMemo(
    () =>
      recipe
        ? [
            {
              title: '\\',
              id: recipe.tableName || 'Recipe Table',
              open: true,
              parentFound: false,
              found: false,
              hidden: false,
              editable: false,
              actions: [{ icon: 'pi pi-plus', code: 'newField' }],
              children: recipe.fields.map((field) => ({
                id: `fields.${field.fieldName}`,
                title: field.fieldName,
                icon: 'pi pi-file',
                open: false,
                parentFound: false,
                found: false,
                hidden: false,
                editable: false,
                autoedit: field.fieldName === rename,
                selected: false,
                childFound: false,
                actions: [
                  { icon: 'pi pi-pencil', code: 'edit' },
                  { icon: 'pi pi-times', code: 'deleteField' },
                ],
              })),
            },
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recipe]
  );

  const toast = useRef<Toast>();
  /**
   * Gestione cambiamento link
   * @param resourceLinks links
   */
  const handleLinkChange = (resourceLinks: Project.ResourceLink[]) => {
    onDocumentDataChange({ ...documentData, resourceLinks });
  };

  /**
   * Gestione dell'icona di link di un evento
   * @param e evento
   * @param name nome del relativo evento
   */
  const handleEventLinkEdit = (e: React.MouseEvent, name: string) => {
    setStartResourcePoint({
      documentId,
      path: [{ idx: 'events' }, { idx: name }],
      pinType: Project.PinType.InOut,
    });
    linkOverlay.current.toggle(e);
  };

  //elemento selezionato
  const selectedRecipeField = useMemo(() => (selectedField ? documentData : undefined), [documentData, selectedField]);

  /**
   * rinomina del campo
   */
  const renameField = useCallback(
    (tagIdToRename: string, newName: string) => {
      const documentData_ = deepCopy(documentData);
      const tagPath = tagIdToRename.split('.');
      const itemRef = getPathReference(documentData_.resourceTree, tagPath);
      if (!itemRef) {
        return;
      }

      //controllo nome non modificato
      const oldName = itemRef.name;
      if (newName === oldName) {
        return;
      }

      //controllo nome duplicato
      const fieldExists = documentData.resourceTree[1].children.some(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (child: any) => child.data?.id.split('_')[1] === newName
      );
      if (fieldExists) {
        toast.current.show({
          severity: 'error',
          summary: 'Errore di rinomina',
          detail: `Il nome del campo "${newName}" è già stato preso.`,
        });
        return;
      }

      if (tagPath.length < 1) {
        documentData_.resourceTree = documentData_.resourceTree.map((item: Project.ResourceTreeElement) => {
          if (item.data.id === tagIdToRename) {
            return {
              ...item,
              name: newName,
            };
          } else {
            return item;
          }
        });
      } else {
        const itemRef = getPathReference(documentData_.resourceTree, tagPath);
        if (itemRef) {
          const oldName = itemRef.name;
          itemRef.name = newName;
          const oldId = itemRef.data.id;
          itemRef.data.id = oldId.split('_')[0] + '_' + newName;
          //aggiornamento chiavi
          if (documentData_.metadata.keys.includes(oldName)) {
            const updatedKeys = documentData_.metadata.keys.map((key: string) => (key === oldName ? newName : key));
            documentData_.metadata.keys = updatedKeys;
          }
        }
      }

      setSelectedField(`fields.${newName}`);
      onDocumentDataChange(documentData_);
    },
    [documentData, onDocumentDataChange]
  );

  const handleItemChange = useCallback(
    (item: Project.DatabaseMetadata) => {
      if (selectedField) {
        onDocumentDataChange({
          ...documentData,
          metadata: item,
        });
      }
    },
    [documentData, onDocumentDataChange, selectedField]
  );

  /**
   *
   * @param action azione eseguita sul campo
   * @param data data
   * @param event evento
   */
  const notifyAction = (action: string, data: string) => {
    const dataObj = JSON.parse(data);
    switch (action) {
      case 'select':
        setSelectedField(dataObj.id);
        break;
      case 'edit':
        renameField(dataObj.id, dataObj.newTitle);
        break;
      case 'newField':
        try {
          const fieldName = defaultFieldName();
          setRename(fieldName);

          const newField: Field = {
            fieldName,
            type: 'string',
          };

          setRecipe((prevRecipe) => ({
            ...prevRecipe,
            fields: [...(prevRecipe.fields || []), newField],
          }));

          //aggiornamento documentData
          const updatedResourceTree = [...documentData.resourceTree];
          const updatedChildren = [...updatedResourceTree[1].children];

          const field2: Project.ResourceTreeElement = {
            name: fieldName,
            data: { id: 'fld-' + documentId.documentId + '_' + fieldName },
            type: Project.TagType.String,
          };

          updatedChildren.push(field2);
          updatedResourceTree[1] = {
            ...updatedResourceTree[1],
            children: updatedChildren,
          };

          const updatedDocumentData = {
            ...documentData,
            resourceTree: updatedResourceTree,
          };

          onDocumentDataChange(updatedDocumentData);

          toast.current.show({
            severity: 'success',
            summary: 'Campo creato',
            detail: `Campo ${fieldName} creato con successo`,
          });
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error.message,
          });
        }
        break;
      case 'deleteField':
        try {
          const fieldId = dataObj.id.split('.')[1];

          setRecipe((prevRecipe) => ({
            ...prevRecipe,
            fields: prevRecipe.fields.filter((field) => field.fieldName !== fieldId),
          }));

          const updatedResourceTree = [...documentData.resourceTree];
          const updatedChildren = updatedResourceTree[1].children.filter(
            (child) => child.data?.id.split('_')[1] !== fieldId
          );

          updatedResourceTree[1] = {
            ...updatedResourceTree[1],
            children: updatedChildren,
          };

          const updatedMetadata = {
            ...documentData.metadata,
            keys: documentData.metadata.keys.filter((key: string) => key !== fieldId), //rimozione chiave dall'array keys
          };

          const updatedDocumentData = {
            ...documentData,
            resourceTree: updatedResourceTree,
            metadata: updatedMetadata,
          };

          setSelectedField(undefined); //chiude campo Fields
          onDocumentDataChange(updatedDocumentData);

          toast.current.show({
            severity: 'success',
            summary: 'Campo eliminato',
            detail: `Campo "${fieldId}" eliminato con successo.`,
          });
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error.message,
          });
        }
        break;
    }
  };

  /**
   * Generazione del nome default del nuovo campo
   * @returns nome default
   */
  const defaultFieldName = () => {
    //estrazione numeri esistenti
    const existingFieldNumbers = recipe.fields
      .map((field) => {
        const match = field.fieldName.match(/field(\d+)/); //regex che controlla se il nome è field seguito da un numero
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((num) => num !== null);

    //ricerca numero minimo che si può inserire
    let newFieldNumber = 1;
    while (existingFieldNumbers.includes(newFieldNumber)) {
      newFieldNumber++;
    }

    return `field${newFieldNumber}`;
  };

  return (
    <>
      <div style={{ display: 'grid', height: '100%', gridTemplateColumns: '1fr 2fr', padding: '1rem', gap: '1em' }}>
        <div className="flex flex-column">
          <Fieldset legend="Recipe" style={{ minHeight: '340px', opacity: !recipe ? 0.5 : 1 }}>
            <div className="card flex flex-column">
              <Treeview
                json={JSON.stringify(recipeTreeItems)}
                selectedItems={[selectedField]}
                notifyAction={notifyAction}
              />
            </div>
          </Fieldset>

          <Fieldset legend="Events" style={{ marginTop: '10px', opacity: !recipe ? 0.5 : 1 }}>
            <div className="flex-col">
              {Events.map((event, index) => (
                <div className="flex flex-row items-center gap-4" key={index}>
                  <div className="mt-3">{event}</div>
                  <Button
                    type="button"
                    icon="pi pi-link"
                    onClick={(e) => handleEventLinkEdit(e, event)}
                    text
                    aria-label="Resolution"
                  />
                </div>
              ))}
            </div>
          </Fieldset>
        </div>

        {!!selectedRecipeField && (
          <RecipeItemEditor
            selectedField={selectedField}
            databaseItem={documentData}
            onChange={handleItemChange}
            onDocumentDataChange={onDocumentDataChange}
          />
        )}
      </div>

      <Toast ref={toast} />

      <OverlayPanel ref={linkOverlay} closeOnEscape dismissable={true}>
        <AutoFocusDiv style={{ width: '750px' }} onBlur={() => linkOverlay.current.hide()}>
          {startResourcePoint && (
            <TreeLink
              startResourcePoint={{ documentId: startResourcePoint.documentId, path: startResourcePoint.path }}
              startResourcePinType={startResourcePoint.pinType}
              resourceLinks={documentData.resourceLinks}
              onChangeData={handleLinkChange}
            />
          )}
        </AutoFocusDiv>
      </OverlayPanel>
    </>
  );
};
